@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";

.back-link {
  padding: 5px 15px;
  position: absolute;
  top: 4px;
  left: 10px;
  transition: all 0.3s ease-in-out;
  height: 36px;
  width: 36px;
  background: #f7f7f7;
  color: $theme-primary-color !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  &:hover {
    background: rgba(var(--button-primary), 0.1);
  }

  .back-link-arrow {
  }

  @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
    padding: 5px 0px 10px;
  }
  @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
    padding: 5px 0px 10px;
  }
  @include tablet {
    padding-left: 0;
  }
}
.back-link-image {
  @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
    height: 30px;
  }
}
// .support-login-wrapper__details {
//   margin: 5rem;
// }

.support-login-wrapper__title {
  color: $theme-font-heading-primary-color;
  @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
    @include genearteRemFontSize(24);
  }
  @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
    @include genearteRemFontSize(30);
  }
}
.support-login-wrapper__sub-title {
  font-weight: $light-font-weight;
  color: $theme-primary-color;
  margin: 8px 0 24px;
  @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
    @include genearteRemFontSize(18);
    margin: 8px 0;
  }
  @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
    @include genearteRemFontSize(18);
    margin: 8px 0;
  }
}
.support-login-wrapper__sub-title a {
  font-weight: $normal-font-weight;
  padding-left: 8px;
}

//otp screen
.custom-form-group {
  min-width: 364px;
  max-width: 364px;
  @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
    max-width: 300px;
    min-width: 300px;
  }
  @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
    min-width: 300px;
    max-width: 300px;
  }
}
.custom-form-group input.custom-form-control {
  border-left: transparent;
  border-right: transparent;
  border-top: transparent;
  border-bottom: 1px solid $theme-border-color;
  border-radius: 0;
  // margin-bottom: 32px;
  margin-bottom: 10px;
  &:focus {
    box-shadow: none;
  }
}
.visbility_icon {
  height: 24px;
  position: absolute;
  right: 10px;
  top: 30%;
  cursor: pointer;
}
