@import '@scss/common/_variables.scss';
@import '@scss/common/_mixins.scss';
@import '@scss/common/_extends.scss';

// .add-more-rolls-wrapper {
// 	// box-shadow: 4px 4px 10px #00000029;
// 	position: relative;
// 	z-index: 2;
//     height: calc(100vh - 70px - 80px);
// 	overflow-y: auto;
// }
// .add-more-rolls-wrapper__header {
// 	background: transparent linear-gradient(81deg, #005ba6 0%, #6efaec 100%) 0% 0%
// 		no-repeat padding-box;
// 	padding: 20px 24px;
// }
// .add-more-rolls-wrapper__header-title {
// 	color: var(--white-color);
// 	font-weight: $normal-font-weight;
// 	@include genearteRemFontSize(20);
// }
// .add-more-rolls-wrapper__sub-title {
// 	padding: 16px 24px;
// 	color: var(--theme-font-heading-secondary-color);
// 	@include genearteRemFontSize(14);
// 	box-shadow: 4px 4px 10px #00000029;
// 	background-color: var(--white-color);
// }
// .add-more-rolls-wrapper__rolls-list {
// 	padding: 16.5px 0;
// }
// .add-more-rolls-wrapper__rolls-wrapper {
// 	padding: 0 24px;
// }
// .add-more-rolls-wrapper__rolls-list-item {
// 	margin-bottom: 16px;
// }
// .add-more-rolls-wrapper__rolls-list-title {
// 	color: var(--theme-font-heading-secondary-color);
// 	margin-bottom: 4px;
// 	@include genearteRemFontSize(16);
// 	font-weight: $normal-font-weight;
// }
// .add-more-rolls-wrapper__rolls-list-text {
// 	color: var(--theme-grey-color);
// 	@include genearteRemFontSize(14);
// 	font-weight: $light-font-weight;
// }
// .add-more-rolls-wrapper__footer {
// 	position: absolute;
// 	bottom: 0;
// 	right: 0;
// 	padding: 8px 24px;
// 	background-color: #f7fbff;
// 	width: 100%;
// }
// .add-more-rolls-wrapper__close-btn {
// 	height: 36px;
// 	width: 36px;
// 	border-radius: 50%;
// 	display: flex;
// 	background-color: transparent;
// 	&:hover {
// 		background-color: #248fbd;
// 	}
// }
// .add-more-rolls-wrapper__close-icon {
// 	margin: auto;
// 	height: 22px;
// 	width: 22px;
// }

//popup
.tabs-padding {
	padding: 16px 0 0 16px;
}
