@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.branding-wrapper__title {
  color: #202124;
  @include genearteRemFontSize(20);
  font-weight: $medium-font-weight;
  margin-bottom: 16px;
}
.branding-input {
  border: 1px dashed $theme-border-color;
  width: 260px;
  height: 84px;
  border-radius: $base-border-radius;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: #fafafa;
    border-color: #1a73e8;
  }
}
.camera-icon {
  margin: auto;
}
.branding-wrapper__text {
  color: #1a73e8;
  margin-top: 8px;
  max-width: 260px;
  text-align: center;
  @include genearteRemFontSize(14);
  font-weight: $medium-font-weight;
}

.branding-wrapper__main-section {
  margin-left: 24px;
  margin-bottom: 20px;
}
.branding-wrapper__main-section-title {
  color: #707070;
  font-weight: $medium-font-weight;
  @include genearteRemFontSize(16);
  line-height: 1.2;
}
.branding-wrapper__main-section-text {
  color: #757575;
  @include genearteRemFontSize(14);
  font-weight: $normal-font-weight;
}

.branding-wrapper__color-box {
  height: 20px;
  width: 20px;
  background-color: #202124;
  &--color {
    background-color: #1a73e8;
  }
}
.branding-wrapper__dropdown {
  border: 1px solid $theme-border-color;
  padding: 16px;
  width: 260px;
}
.branding-wrapper__color-box-text {
  color: #1a73e8;
  margin-left: 8px;
  @include genearteRemFontSize(15);
  display: flex;
  justify-content: center;
  align-items: center;
}
.branding-wrapper__dropdown-item {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
.branding_delete_image {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  // border: 1px solid #dadce0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 4px 4px 10px rgba(#000000, 0.29);
}

@media (max-width: 300px) {
  .branding-input {
    width: 200px !important;
  }
}
