@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.tickets-left-sidebar-wrapper {
  min-width: 300px;
  max-width: 300px;
}
.tickets-details-left-sidebar-wrapper {
  min-width: 240px;
  max-width: 240px;
}

.tickets-left-sidebar-wrapper,
.tickets-details-left-sidebar-wrapper {
  width: 100%;
  border-right: 1px solid $theme-border-color;
  height: calc(100vh - 60px - 64px);
  overflow-y: auto;
  background: #fff;

  .search-ticket-view {
    // margin: 10px;
    // width: 100%;
  }

  .ticket-sidebar-tab {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 38px;
    padding: 0 8px;
    font-size: 14px;

    &--selected {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 38px;
      background: rgba(var(--button-primary), 0.2);
      border-radius: 4px;
      padding: 0 8px;
      font-size: 14px;
      font-weight: 500;
      border-left: 4px solid rgba(var(--button-primary), 1);
    }
  }
}
