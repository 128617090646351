@import "@scss/common/_variables.scss";
@import "@scss/common/_extends.scss";

.centered-aligned-popup {
  position: fixed;
  // top: 30%;
  right: 1px;
  bottom: 0%;
  // transform: translate(-50%, -50%);
  @extend %not-visible;
  @extend %transition;
  background-color: $theme-semi-white-color;
  box-shadow: 4px 4px 10px rgba(#000000, 0.29);
  max-width: 680px;
  width: 100%;
}

.centered-aligned-popup--active {
  @extend %visible;
}

.centered-aligned-popup__header {
  // background: transparent linear-gradient(84deg, #005ba6 0%, #6efaec 100%) 0% 0%
  //   no-repeat padding-box;
  color: #333;
  padding: 18px 15px 18px 30px;
  height: 55px;
  background-color: #fdfdfd;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid #dadce0;
}

.centered-aligned-popup__minimise {
  margin-right: 24px;
}

// .centered-aligned-popup__close {
//   @extend %transition;
//   width: 36px;
//   height: 36px;
//   border-radius: 50%;
//   &:hover {
//     background-color: #248fbd;
//   }
// }

.centered-aligned-popup__footer {
  padding: 8px 24px;
  float: right;
}

.centered-aligned-popup__details-cta {
  margin-left: 20px;
}
.centered-aligned-popup__details-cta-text {
  margin-left: 12px;
}

.centered-aligned-popup__expanded {
  max-width: 1200px;
}

.centered-aligned-popup__body {
  // padding: 24px 0;
  // background-color: #eef1f3;
  // padding-left: 3%;
  // padding-right: 3%;
  // margin: 0 auto;
  background-color: white;
  overflow: auto;
  max-height: calc(100vh - 300px);
  width: 100%;
}

.centered-aligned-popup__body--expanded {
  min-height: 160px;
}

//----------- task popup ------------//

.centered-aligned-popup_task {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @extend %not-visible;
  @extend %transition;
  background-color: $theme-semi-white-color;
  box-shadow: 4px 4px 10px rgba(#000000, 0.29);
  max-width: 680px;
  width: 100%;
  z-index: 1;
}

.centered-aligned-popup_task--active {
  @extend %visible;
}

//----------- Email popup ------------//
.centered-aligned-popup__body-email {
  // padding: 24px 0;
  // background-color: red;
  // padding-left: 3%;
  // padding-right: 3%;
  // margin: 0 auto;
  // overflow: auto;
  // max-height: calc(100vh - 200px);
  height: 100vh;
}

.centered-aligned-popup__body-email--expanded {
  min-height: 160px;
}

.centered-aligned-popup-email {
  position: fixed;
  top: 0;
  height: 100vh;
  max-width: calc(100% - 250px);
  right: 0;
  background-color: var(--white-color);
  z-index: 1000;
  transform: translateX(120%);
  visibility: hidden;
  @extend %transition;
  // border-left: 1px solid var(--theme-border-color);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  max-height: 100vh;
  max-width: 68%;
  width: 100%;
}

.centered-aligned-popup-email--active {
  visibility: visible;
  transform: translateX(0);
}

// .bcc-active-class{
//   height: 50px;
// }

.centered-aligned-popup-email__footer {
  position: absolute;
  bottom: 0;
  right: 0;
  // left: 0;
  padding: 8px 24px;
  // background-color: #f7fbff;
  background-color: #f5f7f9;
  border-top: 1px solid #dadce0;
  width: 100%;
  height: 55px;
  z-index: 1;
}

.attach-button {
  background: linear-gradient(180deg, #fff 0, #f5f7f9 100%);
  border: 1px solid #cfd7df;
  padding: 8px 12px;
  border-radius: 4px;
  outline: 0;
  p {
    font-size: 13px;
    font-weight: 400 !important;
    letter-spacing: 0;
    line-height: 20px;
    color: #333;
    padding-right: 5px;
  }

  &:active {
    box-shadow: inset 0 0 4px 0 rgba(39, 49, 58, 0.26);
    background: #f5f7f9;
  }
}

.email-send-button {
  button {
    background: linear-gradient(180deg, #264966 0, #12344d 100%);
    border: 1px solid #12344d;
    border-radius: 4px;
    height: -webkit-fill-available;
    padding: 8px 12px;
    min-width: 80px;
    outline: 0;
    font-size: 13px;
  }
}

.rdw-option-wrapper {
  border: none !important;

  &:hover {
    box-shadow: none !important;
    background: #ebeff3;
  }
}

.rdw-dropdown-wrapper {
  border: none !important;
  &:hover {
    box-shadow: none !important;
    background: #ebeff3;
  }
}

// .taskPendingLayout-wrapper {
//   .popup-content {
//     top: 0 !important;
//     overflow: hidden;
//     border-radius: 0 !important;
//   }
// }

.attachment_header {
  border-bottom: 1px solid #dadce0;
  height: 54px;
}

.upload-files-header {
  color: #333;
  background-color: #fdfdfd;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.upload-file-text {
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.upload-file-size-info {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  background-color: #e5f2fd;
  border: 1px solid #bbdcfe;
  margin: 10px 0;
  padding: 8px;
  border-radius: 4px;
}
.upload_image {
  width: 140px;
  height: 140px;

  margin: 0 auto;
  display: flex;
}

.drag-drop-text {
  font-size: 13px;
  font-weight: 400;
  // color: ;
}


.email_draft_container .public-DraftEditorPlaceholder-root {
  color: #cfcfcf !important;
  font-size: 10px !important;
  margin-bottom: 4px !important;
  font-weight: 300 !important;
  font-style: inherit;
}

.rdw-dropdown-optionwrapper {
  bottom: 260px;
}

.template_editor_container {
  height: calc(100vh - 280px);
  overflow: auto;
}
