.sla-policy-wrapper {
  overflow: hidden;
  display: flex;

  .sla-policy-card-container {
    height: calc(100vh - 64px);
    overflow-y: auto;
    width: 100%;
  }

  .sla-policy-right-details {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
    overflow-y: auto;
    background: #f7fbff;
    gap: 1rem;
  }
}

.sla-condition-card-wrapper {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 8px;

  .sla-condition-card:nth-child(1) {
    border-radius: 8px 8px 0 0;
  }
  .sla-condition-card:nth-child(3) {
    border-radius: 0 0 8px 8px;
  }
  .sla-condition-card {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    background: #f5f7f9;
    transition: all 0.3s ease-in-out;
    position: relative;

    .delete-sla-condition {
      display: none;
    }

    &:hover {
      .delete-sla-condition {
        display: block;
        cursor: pointer;
      }
    }

    .and-condition-button {
      position: absolute;
      bottom: -24px;
      left: 50%;
      transform: translateY(-50%);
      z-index: 9;
      background: #fff;
      padding: 4px 12px;
      font-size: 12px;
      border-radius: 20px;
      font-weight: 500;
    }
  }

  .sla-no-data-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 8px 8px 0 0;
    background: #f5f7f9;
  }

  .resolution-priority-card {
    padding: 20px;
    border-radius: 4px;
    background-color: #ebeff3;
  }
}

.custom-time-input-container {
  .time-input-data {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 8px;
    width: 170px;
    font-weight: 500;
    font-size: 16px;
    height: 36px;
    display: flex;
    align-items: center;
    cursor: text;

    .no-time-error {
      font-weight: 400;
    }
  }

  .sla-time-input {
    width: 50px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    // transition: all 0.2s ease-in;

    &:focus {
      border: 1px solid #1677ff;
    }
  }
}

.sla-policy-card {
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}

.sla-due-time-condition-card {
  display: grid;
  gap: 16px;
  align-items: center;
  background: #f5f7f9;
  // box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
  //   rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  border-radius: 8px;
  border-bottom: 1px solid #94c1ff;
}

.due-time-and-condition-button {
  z-index: 9;
  background: #1677ff;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  font-size: 12px;
  border-radius: 20px;
  font-weight: 500;
  width: 54px;
  margin: 8px auto;
  position: relative;
  color: #fff;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 1px; /* Line width */
    height: 8px; /* Line height */
    // background: #838383;
    background: #1677ff; /* Line color */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%);
  }

  &::before {
    top: -8px; /* Position above the border */
  }

  &::after {
    bottom: -8px; /* Position below the border */
  }
}
