.solutions-wrapper {
  padding: 2rem;
  overflow-y: auto;
  height: calc(100vh - 188px);
  background: #f5f7f9;

  .solutions-card-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    .solutions-card-container {
      padding: 24px;
      border-radius: 8px;
      transition: all 0.3s ease-in-out;
      background: #fff;

      .solution-edit-dropdown {
        display: none;
      }

      &:hover {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        .solution-edit-dropdown {
          display: block;
        }
      }

      .folder-card {
        border-radius: 4px;
        transition: all 0.3s ease-in-out;

        &:hover {
          background: #f5f7f9;
        }
      }
    }
  }
}

.solutions-accordion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
}

.article-content-editor {
  height: calc(100vh - 306px);
  overflow-y: auto;
  padding: 24px 0;
  border-radius: 4px;

  .codex-editor__redactor {
    padding-bottom: 0 !important;
  }

  .ce-toolbar__content {
    max-width: 82% !important;
  }
  .ce-block__content {
    max-width: 80% !important;
  }
}

.solution-folder-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 8px 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  min-height: 44px;

  .more-options {
    display: none;
  }

  &:hover {
    background: rgba(var(--theme-button-rgba-primary-color), 0.1);

    .more-options {
      display: flex;
    }
  }
}

.solution-folder-card--selected {
  background: var(--theme-button-primary-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &:hover {
    background: var(--theme-button-primary-color);
  }
}

.solution-folder-card__articles {
  margin: 8px 16px;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}
.solution-folder-card__articles_selected {
  // background: rgba(var(--theme-button-rgba-primary-color), 0.1);
}

.folder-articles {
  transition: all 0.3s ease-in-out;
  padding: 6px 16px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  min-height: 40px;
  font-size: 15px;

  .more-options {
    display: none;
  }

  &:hover {
    color: rgba(var(--theme-button-rgba-primary-color), 1);

    .more-options {
      display: flex;
    }
  }
}

.folder-articles--selected {
  background: rgba(var(--theme-button-rgba-primary-color), 0.3);
  color: #000;
}

.articles-leftside {
  height: calc(100vh - 124px);
  overflow-y: auto;
}
