@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";

.company-profile-wrapper__main-section-wrapper {
  // max-width: calc(100% - 257px);
  width: 100%;
  max-height: 100vh;
  height: 100vh;
  overflow-y: hidden;
}
.company-profile-wrapper__main-section {
  // max-width: 753px;
  margin: 0 auto;
}

.branding-wrapper {
  max-width: 753px;
  margin: 24px auto;
}

//company profile navigation

.company-profile-details-wrapper__navigation {
  max-width: 231px;
  min-width: 231px;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  // border-right: 1px solid $theme-border-color;
  //   padding: 8px 0;
  overflow-x: hidden;
  background: #f8fafd;
}
.company-profile-details-wrapper__navigation-container {
  padding-bottom: 12px;
}
.company-profile-details-wrapper__navigation-title {
  font-weight: $medium-font-weight;
  @include genearteRemFontSize(14);
  color: var(--theme-font-heading-secondary-color);
  margin-bottom: 8px;
}
.company-profile-details-wrapper__navigation-list {
  padding: 5px 0 5px 24px;
  max-width: 230px;
  min-width: 230px;
  border-radius: 0 20px 20px 0;
  &:hover {
    background-color: rgba(var(--primary-color), 0.08);
    .company-profile-details-wrapper__navigation-link-title {
      // color: rgba(var(--primary-color), 1);
    }
  }
  &.active {
    background-color: rgba(var(--primary-color), 0.08);
    .company-profile-details-wrapper__navigation-link-title {
      color: rgba(var(--primary-color), 1);
    }
  }
}
.company-profile-details-wrapper__navigation-list_active {
  padding: 5px 0 5px 24px;
  max-width: 230px;
  min-width: 230px;
  border-radius: 0 20px 20px 0;
  background-color: rgba(var(--primary-color), 0.08);
  .company-profile-details-wrapper__navigation-link-title {
    color: rgba(var(--primary-color), 1);
  }
}
.company-profile-details-wrapper__navigation-link-title {
  // font-weight: $medium-font-weight;
  font-weight: 500;
  @include genearteRemFontSize(14);
  // color: var(--theme-grey-color);
  color: #414141;
}

.settings-left-menu {
  .ant-menu-item {
    padding-left: 14px !important;
    height: 26px !important;
    line-height: 26px !important;
    // background: #f8fafd !important;
  }

  .ant-menu-submenu-title {
    background: #f8fafd !important;
    margin: 0 !important;
    border-radius: 0 !important;
    padding-inline-end: 0 !important;
    width: 100% !important;
  }
}
.ant-menu-root {
  border-inline-end: none !important;
}

.ant-menu-sub {
  background: #f8fafd !important;
}

.settings-search-wrapper {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;

  .settings_search {
    position: relative;
    margin: 0 16px;

    .settings-search {
      border: 1px solid #fff;
      outline: none;
      height: 36px;
      border-radius: 24px;
      background: #f3f6fc;
      padding: 4px 16px 4px 32px;
      width: 100%;
      transition: all 0.3s ease-in-out;

      &::placeholder {
        font-size: 13px;
      }
    }

    .search-icon {
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translate(50%, -50%);
    }
  }
}
