@import "@scss/common/_variables.scss";

.datatable-table_top_container {
  width: 95%;
  margin: auto;
  height: 44px;
  position: sticky;
  background-color: transparent;
  border: 1px solid rgb(223, 227, 235);
  display: flex;
  align-items: center;
  .datatable-table_top_container_items {
    width: 100%;
  }
}
.datatable_container {
  // max-height: calc(100vh - 64px - 60px - 132px - 32px);
  max-height: calc(100vh - 300px);
  overflow: auto;
  width: 95%;
  margin: auto;
}
.datatable_table {
  overflow: auto;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

.datatable_table th:first-child {
  position: sticky;
  left: 0;
  z-index: 102;
  background-color: #f8f8ff;
  text-align: center !important;
}

.datatable_table_thead {
  justify-content: center;
  text-align: center;
  align-items: center;

  .datatable_table_th_abosulute {
    position: absolute;
    right: 7px;
    z-index: 101;
    top: 7px;
    background: #dfdfff;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .datatable_table_th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    height: 44px;
    border: 0.5px solid rgb(223, 227, 235);
    background-color: #f8f8ff;
    min-width: 100px;
    transition: all 500ms ease-in;
    font-family: $font-family;
    color: rgb(51, 71, 91);
    font-weight: 600;
    font-size: 12px;
    text-align: left;
    margin-left: 5px;
    // text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
    // white-space: pre-wrap;
  }
}
.active_background {
  background-color: rgba(var(--primary-color), 0.06) !important;
}
.datatable_table_tr {
  // &:hover {
  //   background-color: rgba(var(--primary-color), 0.06) !important;
  // }

  .datatable_table_td {
    font-family: $font-family;
    font-size: 14px;
    text-align: left;
    min-width: 100px;
    height: 2.7rem;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 0.5px solid rgb(223, 227, 235);
    width: 400px;
    color: #33475b;
    font-weight: 400;
    cursor: pointer;
    max-width: 300px;
    position: relative;

    .sheet-view-input {
      border: none;
      transition: all 0.3s ease-in-out;
      border: 1px solid #ffffff;
      border-radius: 4px;

      &:hover,
      :focus {
        border: 1px solid #408dfb;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 4px;
      }
    }

    .sheet-view-textarea {
      height: 28px;
      border: none;
      border: 1px solid #ffffff;
      border-radius: 4px;

      &:hover,
      :focus {
        border: 1px solid #408dfb;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 4px;
      }
    }

    // .table-row-preview {
    //   display: none;
    //   position: absolute;
    //   top: 50%;
    //   right: 10px;
    //   transform: translateY(-50%);
    // }
  }

  .datatable_table_td_icon {
    font-family: $font-family;
    font-size: 14px;
    text-align: left;
    height: 2.7rem;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgb(223, 227, 235);
    width: 400px;
    color: #33475b;
    cursor: pointer;
    max-width: 300px;
  }

  .datatable_table_td_actions {
    display: none;
    position: sticky;
    right: 0;
    background: #fff;
  }

  &:hover {
    .datatable_table_td_actions {
      display: block;
    }

    // .table-row-preview {
    //   display: block;
    // }
  }
}

//-------------------- Main Table Section -------------------//
table {
  table-layout: fixed;
}
th,
td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}
// th:after,
// th:before {
//   content: "";
//   position: absolute;
//   left: 0;
//   width: 100%;
// }

// th:before {
//   border-bottom: 1px solid rgb(223, 227, 235);
//   top: -1px;
//   border-top: 1px solid rgb(223, 227, 235);
// }

// th:after {
//   bottom: -1px;
//   border-bottom: 2px solid rgb(223, 227, 235);
// }

//-------------- sticky portion ---------------//
.datatable_table th:first-child {
  position: sticky;
  left: 0;
  z-index: 102;
  background-color: #f8f8ff;
}
.datatable_table td:first-child {
  position: sticky;
  left: 0;
  z-index: 101;
  background-color: white;
  // display: flex;
  // justify-content: center;
  text-align: center;
  width: 70px;
  // &:hover {
  //   background-color: transparent !important;
  // }
}

.datatable_table th:nth-child(2) {
  position: sticky;
  left: 100px;
  z-index: 102;
  background-color: #f8f8ff;
  // box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.1); /* Adjust shadow properties as needed */
}

.datatable_table td:nth-child(2) {
  position: sticky;
  left: 100px;
  z-index: 101;
  background-color: white;
  // &:hover {
  //   background-color: transparent !important;
  // }
  // box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.1); /* Adjust shadow properties as needed */
}

.absolute-header {
  position: absolute;
  right: 10px;
}
.stage_color {
  padding: 5px;
  border-radius: 5px;
  font-size: 13px;
}

.number-validated,
.number-notvalidated {
  font-size: 13px;
  font-weight: 500;
  margin-top: 5px;
}

.number-validated {
  color: #00a38d;
}

.number-notvalidated {
  color: red;
}
