@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.detail-page_container {
  background-color: #f7fbff;
  max-height: calc(100vh - 64px);
  overflow-y: auto;
}
.pending-wrapper__main-section {
  padding: 25px 0 0;
}
.pending-wrapper__main-left-section {
  // width: 30%;
  width: 100%;
}
.pending-wrapper__main-section-form {
  // max-width: 316px;
  // width: 100%;
  // padding: 0 24px;
}
.form-group {
  display: block;
  margin-bottom: 8px;
}
.form-group__label {
  display: block;
  margin: 0px 5px 8px;
  color: #5f6368;
  font-weight: $normal-font-weight;
  @include genearteRemFontSize(14);
}
.form-group__input-control {
  width: 100%;
  max-width: 291px;
  min-width: 291px;
  height: 44px;
  line-height: 44px;
  border: 1px solid #e8f0fe;
  padding: 0 16px;
  border-radius: $base-border-radius;
  &::placeholder {
    @include genearteRemFontSize(14);
    color: #202124;
    font-weight: $normal-font-weight;
  }
}

.down-arrow {
  margin-right: 10px;
}

.pending-wrapper__main-section-title {
  color: #202124;
  font-weight: $medium-font-weight;
  @include genearteRemFontSize(14);
}

.form-group__input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}

// .task-list-wrapper {
//   max-width: 55px;
//   width: 100%;
//   padding: 12px 0px 0px;
//   cursor: pointer;
// }

// .task-list-container {
//   height: 36px;
//   width: 36px;
//   background-color: $theme-button-primary-color;
//   border-radius: 50%;
//   margin: 0 auto;
// }

.task-list {
  height: 18px;
  width: 18px;
  margin: auto;
}

.task-list-title {
  font-weight: $light-font-weight;
  @include genearteRemFontSize(12);
  margin-top: 5px;
}
.section-divider {
  border-top: 1px solid $theme-border-color;
}
.pending-wrapper__main-left-section-details {
  // padding-left: 24px;
}
.pending-wrapper__main-section-btn {
  // border: 1px solid #e8f0fe;
  // color: #1a73e8;
  // height: 40px;
  // line-height: 40px;
  // margin: 24px;
  // width: 100%;
  // border-radius: $base-border-radius;
  // max-width: 291px;
  // width: 100%;
  // background-color: #f7fbff;
}
.footer_container,
.sheet_footer_container {
  width: 100%;
  // height: 56px;
  z-index: 1000;
  bottom: 0;
  position: fixed;
  right: 0;
  // background-color: rgb(245, 248, 250) !important;
  // background-color: #1a73e8;
  background-color: #f5f8fa;
  border-top: 1px solid #cbd6e2;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  transition: all 1s ease;
  .text {
    color: #33475b;
    font-size: 14px;
    padding-left: 10px;
  }
}

.footer_container {
  height: 56px;
}
.sheet_footer_container {
  height: 50px;
}
.fixed-task-container {
  position: absolute;
  right: 20px;
  bottom: 80px;
  background-color: white;
  min-width: 250px;
  min-height: 100px;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-shadow: 4px 4px 10px #00000029;
}
.fixed-task-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

// .custom-modal-height .ant-modal-content {
//   height: 700px;
// }
.modal-body-scrollable {
  max-height: calc(100vh - 230px);
  overflow-y: auto;
  padding: 16px; /* Optional padding */
  border-bottom: 1px solid #dadce0;
}

:where(.css-dev-only-do-not-override-apn68).ant-modal {
  padding-bottom: 0 !important;
}
