@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.common-subheader-wrapper {
  // padding: 6px 24px;
  padding: 4px 24px;
  box-shadow: 0px 3px 6px #00000029;
  position: sticky;
  left: 0;
  right: 0;
  top: 62px;
  background-color: $white-color;
  z-index: 3;
  height: 52px;
  display: flex;
}
.common-subheader-wrapper__submenu-name {
  color: var(--theme-font-heading-secondary-color);
  @include genearteRemFontSize(16);
  font-weight: $medium-font-weight;
}
.preview-btn {
  color: $theme-button-primary-color;
  background-color: transparent;
  border: none;
}
.Service-text {
  margin-left: 6px;
  color: #707070;
  cursor: pointer;
  font-weight: $medium-font-weight;
  @include genearteRemFontSize(16);
}
