@import "../normalize.scss";
@import "../extends.scss";
@import "../mixins.scss";
@import "../variables.scss";

.form-control {
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius !important;
  // background-color: rgb(245, 248, 250) !important;
  background-color: transparent !important;
  height: 44px;
  line-height: 44px;
  padding: 0 16px;
  &::placeholder {
    @include genearteRemFontSize(14);
    // color: $theme-border-color;
    color: #abb2b9;
  }
  &:focus {
    box-shadow: none;
    background-color: white !important;
  }
  &:disabled {
    background-color: #fafafa !important;
  }
}
.form-control-white {
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius !important;
  // background-color: rgb(245, 248, 250) !important;
  background-color: white;
  height: 44px;
  line-height: 44px;
  padding: 0 16px;
  &::placeholder {
    @include genearteRemFontSize(14);
    color: $theme-border-color;
  }
  &:focus {
    box-shadow: none;
    background-color: white !important;
  }
}
.form-control-white-texarea {
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius !important;
  // background-color: rgb(245, 248, 250) !important;
  background-color: white;
  // height: 44px;
  line-height: 44px;
  padding: 0 16px;
  &::placeholder {
    @include genearteRemFontSize(14);
    color: $theme-border-color;
  }
  &:focus {
    box-shadow: none;
    background-color: white !important;
  }
}
.form-control-textarea {
  width: 100%;
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius !important;
  // background-color: rgb(245, 248, 250);
  background-color: transparent !important;
  // height: 44px;
  line-height: 18px;
  padding: 10px 16px;
  &::placeholder {
    @include genearteRemFontSize(14);
    color: #abb2b9;
  }
  &:focus {
    box-shadow: none;
    border: 1px solid #86b7fe;
    background-color: white !important;
  }
}

// .form-control {
// 	height: 44px;
// 	border-color: var(--theme-border-color);
// 	&::placeholder {
// 		/* Chrome, Firefox, Opera, Safari 10.1+ */
// 		color: var(--theme-border-color);
// 		opacity: 1; /* Firefox */
// 	}

// 	&:-ms-input-placeholder {
// 		/* Internet Explorer 10-11 */
// 		color: var(--theme-border-color);
// 	}

// 	&::-ms-input-placeholder {
// 		/* Microsoft Edge */
// 		color: var(--theme-border-color);
// 	}
// }

.form-error {
  color: rgb(217, 48, 37) !important;
  font-size: 12px;
  margin-top: 8px;
}

.form-label {
  margin-bottom: 5px;
  margin-top: 7px;
  font-size: 14px;
  color: #33475b;
  font-weight: 500;
  min-width: 300px;
}
.form-label-inline {
  margin-bottom: 5px;
  margin-top: 7px;
  font-size: 13px;
  color: #33475b;
  font-family: "Lexend Deca", Helvetica, Arial, sans-serif;
  min-width: 300px;
}

.inline-input-drag {
  position: relative;
  width: 400px;
}
.inline-input-drag input {
  background-color: #fff;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding-right: 36px;
}
.inline-input-drag .img {
  position: absolute;
  top: 18px;
  right: 5px;
  // height: 30px;
  // width: 24px;
}
