@import "../extends.scss";

.touchable-text {
  cursor: pointer; /* Change the cursor to a hand icon when hovering over the text */
  transition: transform 0.2s; /* Add a smooth transition effect to the transform property */
}

.touchable-text:hover {
  // transform: scale(1.1); /* Scale the text up when hovered over */
}

.touchable-text:active {
  transform: scale(0.98); /* Scale the text down when clicked */
  color: #64a3f6;
  // #4a8feb
}

.touchable-text-white {
  cursor: pointer; /* Change the cursor to a hand icon when hovering over the text */
  transition: transform 0.2s; /* Add a smooth transition effect to the transform property */
}

.touchable-text-white:hover {
  // transform: scale(1.1); /* Scale the text up when hovered over */
}

.touchable-text-white:active {
  transform: scale(0.98); /* Scale the text down when clicked */
  color: white;
}

.font_size {
  &__12 {
    font-size: 12px !important;
  }
  &__13 {
    font-size: 13px !important;
  }
  &__14 {
    font-size: 14px !important;
  }
  &__16 {
    font-size: 16px !important;
  }
  &__18 {
    font-size: 18px !important;
  }
}
.w {
  &-3 {
    width: 3% !important;
  }
  &-5 {
    width: 5% !important;
  }
  &-10 {
    width: 10% !important;
  }
  &-15 {
    width: 15% !important;
  }
  &-20 {
    width: 20% !important;
  }
  &-30 {
    width: 30% !important;
  }
  &-33 {
    width: 33% !important;
  }
  &-40 {
    width: 40% !important;
  }
  &-45 {
    width: 45% !important;
  }
  &-60 {
    width: 60% !important;
  }
  &-70 {
    width: 70% !important;
  }
  &-80 {
    width: 80% !important;
  }
  &-85 {
    width: 85% !important;
  }
  &-90 {
    width: 90% !important;
  }
}

.text {
  &_red {
    &__18 {
      font-size: 18 !important;
      color: #ff0000;
    }
  }
  &_blue {
    &__18 {
      font-size: 18 !important;
      color: #1a73e8;
    }
  }
}

.header_blue_text {
  color: #1a73e8;
  font-size: 12px;
  &__14 {
    font-size: 14px;
    font-weight: 500 !important;
    color: #1a73e8 !important;
  }
  &__15 {
    font-size: 15px;
    font-weight: 500 !important;
    color: #1a73e8;
  }
  &__16 {
    font-size: 16px;
    font-weight: 500 !important;
    color: #1a73e8;
  }
  &__17 {
    font-size: 17px;
    font-weight: 500 !important;
    color: #1a73e8;
  }
  &__18 {
    font-size: 18px;
    font-weight: 500 !important;
    color: #1a73e8;
  }
  &__20 {
    font-size: 20px;
    font-weight: 500 !important;
    color: #1a73e8;
  }
}

.header_text {
  &__12 {
    font-weight: 600 !important;
    font-size: 12px;
    color: rgb(51, 71, 91);
  }
  &__14 {
    font-size: 14px;
    font-weight: 500 !important;
    color: #202124;
  }
  &__15 {
    font-size: 15px;
    font-weight: 500 !important;
    color: #202124;
  }
  &__16 {
    font-size: 16px;
    font-weight: 500 !important;
    color: #202124;
  }
  &__17 {
    font-size: 17px;
    font-weight: 500 !important;
    color: #202124;
  }
  &__18 {
    font-size: 18px;
    font-weight: 500 !important;
    color: #202124;
  }
  &__20 {
    font-size: 20px;
    font-weight: 500 !important;
    color: #202124;
  }
  &__22 {
    font-size: 22px;
    font-weight: 500 !important;
    color: #202124;
  }
  &__24 {
    font-size: 24px;
    font-weight: 500 !important;
    color: #202124;
  }
  &__26 {
    font-size: 26px;
    font-weight: 500 !important;
    color: #202124;
  }
  &__30 {
    font-size: 30px;
    font-weight: 500 !important;
    color: #202124;
  }
}

.main_header_text {
  color: #202124;
  font-size: 20px;
  font-weight: 500;
}

.desc_text {
  color: #434d5f;
  font-size: 16px;
}

.label_text {
  font-size: 14px;
  color: #434d5f;
}

.small_text {
  &__8 {
    font-size: 8px;
    color: #434d5f;
  }
  &__10 {
    font-size: 10px;
    color: #434d5f;
  }
  &__12 {
    font-size: 12px;
    color: #434d5f;
  }
  &__13 {
    font-size: 13px;
    color: #434d5f;
  }
  &__14 {
    font-size: 14px;
    color: #434d5f;
  }
  &__15 {
    font-size: 15px;
    color: #434d5f;
  }
  &__16 {
    font-size: 16px;
    color: #434d5f;
  }
  &__18 {
    font-size: 18px;
    color: #434d5f;
  }
  &__22 {
    font-size: 22px;
    color: #434d5f;
  }
  &__26 {
    font-size: 2.5rem;
    color: #434d5f;
    font-weight: 200;
  }
}

.small_text_blue {
  &__12 {
    font-size: 12px;
    color: #1a73e8;
  }
  &__13 {
    font-size: 13px;
    color: #1a73e8;
  }
  &__14 {
    font-size: 14px;
    color: #1a73e8;
  }
  &__15 {
    font-size: 15px;
    color: #1a73e8;
  }
  &__16 {
    font-size: 16px;
    color: #1a73e8;
  }
}

.sub_text {
  &__12 {
    font-size: 12px;
    color: #707070;
  }
  &__13 {
    font-size: 13px;
    color: #707070;
  }
  &__14 {
    font-size: 14px;
    color: #707070;
  }
  &__15 {
    font-size: 15px;
    color: #707070;
  }
  &__16 {
    font-size: 16px;
    color: #707070;
  }
}
.tag_text {
  color: #7890a1 !important;
}

.border-radius {
  &__5 {
    border-radius: 5px;
  }
  &__10 {
    border-radius: 10px;
  }
}
