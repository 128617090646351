.profile_circle_section {
  height: 150px !important;
  width: 150px !important;
  border-radius: 50%;
  background-color: #ff9932 !important;
  h6 {
    color: white;
    font-size: 40px;
  }
}
.profile-form-container{
    width: 70%;
    margin: 0 auto;
}