@import "@scss/common/_variables.scss";

.inline_form_input {
  border: none;
  // border-bottom: 1px solid $theme-main-text-color;
  margin-top: 5px;
  font-size: 14px;
  font-family: $font-family;
  // padding: 10px;
  height: 38px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  &::placeholder {
    color: #b1b2b5;
  }
  &:focus {
    border-radius: 5px !important;
    background-color: #f2f2ff !important;
    border: 1px solid #408dfb !important;
  }
  &:hover {
    background-color: #f2f2ff !important;
    border: 1px solid #408dfb !important;
    border-radius: 5px !important;
  }
}

.inline_form_input_date {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
  // border-bottom: 1px solid $theme-main-text-color;
  margin-top: 5px;
  font-size: 14px;
  font-family: $font-family;
  padding: 10px;
  height: 38px;
  width: 100%;
  border-bottom: none;
  &::placeholder {
    color: #b1b2b5;
  }
  &:focus {
    transition: all 100ms ease;
    border-radius: 4px;
    border: none;
    box-shadow: none;
  }
  &:focus {
    border-radius: 5px !important;
    background-color: #f2f2ff !important;
    border: 1px solid #408dfb !important;
  }
  &:hover {
    background-color: #f2f2ff !important;
    border: 1px solid #408dfb !important;
    border-radius: 5px !important;
  }
}
.inline_form-control-textarea {
  border: none;
  margin-top: 5px;
  font-size: 14px;
  font-family: $font-family;
  padding: 8px;
  height: 68px;
  width: 100%;
  &::placeholder {
    color: #b1b2b5;
  }
  &:focus {
    border-radius: 5px !important;
    background-color: #f2f2ff !important;
    border: 1px solid #408dfb !important;
  }
  &:hover {
    background-color: #f2f2ff !important;
    border: 1px solid #408dfb !important;
    border-radius: 5px !important;
  }
}
