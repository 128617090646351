@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";

.login-header-wrapper {
  background-color: $white-color;
  padding: 6px 0;
  border-bottom: 1px solid $theme-border-color;
  position: sticky;
  top: 0;
  z-index: 1;
}

.login-header-wrapper__logo {
  max-width: 156px;
  height: 52px;
}
.login-header-wrapper__logo-image {
  height: 30px;
  @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
    height: 30px;
  }
  @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
    height: 30px;
  }
}
