@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";
.pending-wrapper__main-body {
  // max-width: calc(100% - 682px);
  width: 50%;
  padding: 24px 17px;
  max-height: 100vh;
  overflow-y: auto;
}
.pending-wrapper__main-section-filter {
  // border: 1px solid var(--sky-blue-color);
  // border-top: 1px solid var(--sky-blue-color);
  padding: 9px 16px;
}
.pending-wrapper__main-section-filter-icon {
  margin-right: 8px;
}
.pending-wrapper__main-section-filter-text {
  color: var(--theme-blue-primary-color);
  @include genearteRemFontSize(14);
}
.pending-wrapper__main-body ul li {
  @include genearteRemFontSize(14);
}
.task-assigned {
  border: 1px solid var(--sky-blue-color);
  padding: 23px 16px 23px;
  background-color: var(--white-color);
  // margin-bottom: 16px;
}
.pending-wrapper__main-body-title {
  color: var(--theme-font-terciary-color);
  @include genearteRemFontSize(14);
  font-weight: $normal-font-weight;
  span {
    font-weight: $medium-font-weight;
  }
}
.pending-wrapper__main-body-status {
  color: var(--theme-font-terciary-color);
  @include genearteRemFontSize(14);
  font-weight: $normal-font-weight;
  &--overdue {
    color: #ec7063;
  }
}
.pending-wrapper__main-body-description {
  color: var(--theme-font-heading-secondary-color);
  @include genearteRemFontSize(14);
  font-weight: $medium-font-weight;
  margin-left: 24px;
}
.pending-wrapper__main-body-heading {
  color: var(--theme-font-terciary-color);
  @include genearteRemFontSize(16);
  margin-bottom: 8px;
  font-weight: $normal-font-weight;
}

.pending-wrapper__main-body-heading_bottom {
  color: #1a73e8;
  @include genearteRemFontSize(16);
  margin-bottom: 8px;
  font-weight: $normal-font-weight;
  text-decoration: underline;
}

.all-user-dropdown {
  height: 40px;
  line-height: 40px;
  border: 1px solid var(--theme-border-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 8px 0 16px;
  gap: 8px;
  cursor: pointer;
}
.all-user-dropdown-title {
  font-weight: $medium-font-weight;
  color: var(--theme-grey-color);
  @include genearteRemFontSize(14);
}

.pending-wrapper__main-body-link {
  color: var(--theme-font-terciary-color);
  font-weight: $medium-font-weight;
  @include genearteRemFontSize(14);
  transition: 0.3s all ease-in-out;
}
.pending-wrapper__main-body-list_active {
  padding-bottom: 16px;
  cursor: pointer;
  position: relative;
  &:not(:last-child) {
    margin-right: 24px;
  }
  .pending-wrapper__main-body-link {
    color: #1a73e8 !important;
  }
  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: #1a73e8;
    bottom: 0;
    left: 0;
  }
}
.pending-wrapper__main-body-list {
  padding-bottom: 16px;
  cursor: pointer;
  position: relative;
  &:not(:last-child) {
    margin-right: 24px;
  }
  &.active {
    .pending-wrapper__main-body-link {
      color: var(-var(--theme-button-primary-color));
    }
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: var(-var(--theme-button-primary-color));
      bottom: 0;
      left: 0;
    }
  }
  &:hover {
    .pending-wrapper__main-body-link {
      color: var(--theme-button-primary-color);
    }
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: var(--theme-button-primary-color);
      bottom: 0;
      left: 0;
    }
  }
}
.pending-wrapper__main-body-list_active {
  padding-bottom: 16px;
  cursor: pointer;
  position: relative;
  &:not(:last-child) {
    margin-right: 24px;
  }
  .pending-wrapper__main-body-link {
    color: #1a73e8 !important;
  }
  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: #1a73e8;
    bottom: 0;
    left: 0;
  }
}
.pending-wrapper__main-body-list {
  padding-bottom: 16px;
  cursor: pointer;
  position: relative;
  &:not(:last-child) {
    margin-right: 24px;
  }
  &.active {
    .pending-wrapper__main-body-link {
      color: var(-var(--theme-button-primary-color));
    }
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: var(-var(--theme-button-primary-color));
      bottom: 0;
      left: 0;
    }
  }
  &:hover {
    .pending-wrapper__main-body-link {
      color: var(--theme-button-primary-color);
    }
    &::after {
      content: "";
      position: absolute;
      height: 3px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      width: 100%;
      background-color: var(--theme-button-primary-color);
      bottom: 0;
      left: 0;
    }
  }
}
.pending-wrapper__main-body-link {
  color: var(--theme-font-terciary-color);
  font-weight: $medium-font-weight;
  @include genearteRemFontSize(14);
  transition: 0.3s all ease-in-out;
}
.pending-wrapper__main-body-list_active {
  padding-bottom: 16px;
  cursor: pointer;
  position: relative;
  &:not(:last-child) {
    margin-right: 24px;
  }
  .pending-wrapper__main-body-link {
    color: #1a73e8 !important;
  }
  &::after {
    content: "";
    position: absolute;
    height: 3px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    background-color: #1a73e8;
    bottom: 0;
    left: 0;
  }
}

.pending-wrapper__main-body-upcoming {
  // margin: 32px 0 16px;
}
.task-assigned-notes {
  border: 1px solid var(--sky-blue-color);
  padding: 15px 16px;
  background-color: var(--white-color);
  margin-bottom: 16px;
}
.create-user-button {
  background-color: var(--theme-blue-primary-color);
  color: var(--white-color);
  padding: 0px 16px;
  border: 1px solid transparent;
  border-radius: $base-border-radius;
  display: flex;
  align-items: center;
  font-weight: $medium-font-weight;
  &--department {
    padding: 8px 16px;
  }
}
.create-filter-button {
  background-color: transparent;
  color: rgba(var(--primary-color), 1);
  border: 1px solid rgba(var(--primary-color), 1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  // font-weight: $medium-font-weight;
  padding: 4px 16px;
  font-size: 14px;
}

//email popup css
.email-popup_container {
  width: 100%;
  // margin: 0 auto;
  // max-height: calc(100vh - 70px);
  // max-height: calc(100vh - 120px);
  // max-height: calc(100vh - 162px);
  max-height: calc(100vh - 110px);
  overflow: auto;
  // padding-left: 2%;
  // padding-right: 4%;
}
.email_labels {
  // width: 20px;
  width: 52px;
  margin-left: 30px;
  font-size: 15px;
}
.email_card {
  background-color: white !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.email_card_body {
  background-color: white !important;
  border: none !important;
}
.card_header {
  border: none !important;
  height: 50px;
  border-bottom: 1px solid #efefef !important;
}

.card_table {
  cursor: pointer;
  .card_table_header {
    td {
      font-size: 16px;
      height: 40px;
      border-bottom: 1px solid #efefef !important;
      font-weight: 500;
    }
  }
  .card_table_body {
    td {
      font-size: 14px;
      height: 60px;
      border-bottom: 1px solid #efefef !important;
      .text {
        font-size: 12px;
      }
    }
  }

  tr:hover td:first-child,
  tr td:first-child:hover ~ td {
    background: transparent;
  }
  td:first-child {
    background: transparent;
    width: 250px;
  }
  tr:hover td {
    // background: rgb(232, 246, 255);
  }
  tr td:first-child:hover {
    // background: red;
  }
}

//email-info popup section

.email-popup_header-section {
  height: 50px;
  margin-bottom: 10px;
  .email-popup_left-section {
  }
  .email-popup_right-section {
  }
}
.email-popup_border_section {
  margin-top: 20px;
  margin-bottom: 20px;

  border-bottom: 1px solid #dadce0;
}
.email-popup_body-section {
  p {
    font-size: 14px;
  }
}
.email-popup_body_submit-section {
}
.email-popup_button {
  border: none;
  padding: 5px;
  margin-right: 10px;
  width: 100px;
  border-radius: 5px;
  border: 1px solid #dadce0;
  background-color: #efefef;
  font-size: 14px;
}
.email-popup_container_email_body {
  border-bottom: 1px solid #dadce0;
  // overflow: hidden;
  // line-height: 50px;
  // height: 20px;
}
.email-popup_container_form-control_body {
  border: none;
  height: 40px;
  padding-left: 30px;
  box-shadow: none;
  &:focus {
    border: none;
    box-shadow: none;
  }

  &::placeholder {
    color: #cfcfcf;
    font-size: 13px;
    margin-bottom: 4px;
    font-weight: 300;
  }
}

.public-DraftEditorPlaceholder-inner {
  color: #bababa !important;
  font-size: 14px !important;
}

.notes_select_container {
  background-color: white;
  border-top: 1px solid #dadce0;
  border-bottom: 1px solid #dadce0;
  overflow: auto;
  max-height: calc(40vh - 40px);
}

.form-control-date_picker {
  z-index: 1001;
  position: relative;
}

.activity_popup-container {
  grid-gap: 24px;
  margin: 0 auto;
  margin-top: 10px;
  align-items: center;
  width: 80%;
}
.activity_popup-main {
  max-height: calc(100vh - 145px);
  height: calc(100vh - 145px);
  overflow: auto;
}
.notes_container {
  // width: 90%;
  margin: 0 auto;
}

.unique_task_count {
  position: absolute;
  right: -20px;
  // background-color: red;
  // color: #fff;
  // height: 20px;
  // width: 20px;
  // border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  top: -10px;
}
