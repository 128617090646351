.bot-flow-container {
  width: 100%;
  height: calc(100vh - 65px);
}

.platform_container {
  padding: 10px 20px;
  border: 3px solid #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: fit-content;
}
.chatbot-avatar-selctor {
  padding: 6px;
  border: 3px solid #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  width: fit-content;
}

.platform_add_container {
  width: 68px;
  height: 68px;
  background: #4cc793;
  border-radius: 50%;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  .add-rounded {
    border-radius: 50%;
  }
}

.bot-color-picker {
  width: 24px;
  height: 24px;
  top: 40px;
  right: 10px;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
}

.color-picker-wrapper {
  position: absolute;
  top: 35px;
  left: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 99;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 8px;
  background: #fff;
}

.color-picker-box {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 6px;
  background-image: url("./picker.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.bot_container {
  height: calc(100vh - 190px);
  overflow: auto;
}
.bot-list-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow: auto;
  width: 95%;
  margin: auto;
  margin-bottom: 20px;

  .bot-card-add-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    border-radius: 8px;

    h6 {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      h6 {
        color: rgba(var(--button-primary), 1) !important;
      }
    }
  }

  .bot-card-container {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    border-radius: 8px;
    display: grid;
  }
}

.chatbot-navbar {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}

.chatbot-sharelink {
  border-left: 1px solid rgb(224, 224, 224);
}

.shareable-code {
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 1rem;
  width: fit-content;
}

.chatbot-all-avatar {
  border-radius: 50%;
}

.sketch-picker {
  box-shadow: none !important;
  border-radius: 6px !important;
}

.bot-position-container {
  width: 40px;
  height: 40px;
  border: 2px solid #ccc;
  border-radius: 6px;
  position: relative;
  transition: all 0.3s ease-in-out;

  .bot-left-bottom-marker {
    width: 16px;
    height: 6px;
    background: #aeaeae;
    border-radius: 2px;
    position: absolute;
    bottom: 4px;
    left: 4px;
    transition: all 0.3s ease-in-out;
  }

  .bot-right-bottom-marker {
    width: 16px;
    height: 6px;
    background: #aeaeae;
    border-radius: 2px;
    position: absolute;
    bottom: 4px;
    right: 4px;
    transition: all 0.3s ease-in-out;
  }

  .bot-right-center-marker {
    width: 16px;
    height: 6px;
    background: #aeaeae;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
  }
}

.add-position-border {
  border: 2px solid #2c5cc5;

  .bot-right-center-marker,
  .bot-right-bottom-marker,
  .bot-left-bottom-marker {
    background: #2c5cc5;
  }
}

.bot-creator-window {
  box-shadow: 0 0 1px rgba(95, 108, 114, 0.08), 0 0 6px rgba(95, 108, 114, 0.05);
  border-radius: 6px;
  border: 1px solid #f0f0f0;
  height: 100%;
  position: relative;
  height: calc(100vh - 172px);

  .window-top-bar {
    background: #f0f4f7;
    display: flex;
    align-items: center;
    padding: 0 20px;
    gap: 10px;
    height: 50px;

    .window-action-button {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: red;
    }

    .close-color {
      background: #d56363;
    }

    .maximize-color {
      background: #ffd248;
    }

    .minimize-color {
      background: #9dc75a;
    }
  }

  .bot-demo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    transition: all 0.3s ease-in-out;
    background: #e7e7e7;
    outline: none;
    border: none;
    z-index: 9999;
  }
}

.edit-bot-height {
  height: calc(100vh - 230px);
}

.bot-profile-dummy {
  width: 40px;
  height: 40px;
  background: #e7e7e7;
  border-radius: 50%;
}

.bot-sample-message {
  background: #f8f8f8;
  border-radius: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  width: fit-content;

  .dummy-text-message {
    width: fit-content;
  }
}
.bot_preview_model_container {
  max-height: calc(100vh - 200px);
  overflow: auto;
}

.custom-bot-type-selector {
  .ant-radio-button-wrapper {
    height: 100% !important;
  }
}

.bot-type-badge {
  line-height: normal !important;

  .ant-badge-status-text {
    color: #434d5f !important;
  }
}
