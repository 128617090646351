@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";

.try-superteam-wrapper {
  max-width: 389px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 576px) {
    margin-top: 30px;
  }
}
.try-superteam-wrapper__details {
  margin: auto;
}
.try-superteam-wrapper__title {
  color: $theme-font-heading-primary-color;
  max-width: 262px;
  line-height: 1.2;
  @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
    @include genearteRemFontSize(24);
  }
  @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
    @include genearteRemFontSize(30);
  }
}
.try-superteam-wrapper__sub-title {
  font-weight: $normal-font-weight;
  color: $theme-primary-color;
  margin: 8px 0 16px;
  line-height: 1.2;
  @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
    @include genearteRemFontSize(18);
    margin: 10px 0;
  }
  @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
    @include genearteRemFontSize(18);
    margin: 15px 0;
  }
}

.back-link {
  padding: 5px 15px;
  @media (max-width: $iphone-devices-max-width+px) {
    // padding: 5px 0px 10px;
    display: none;
  }
  @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
    // padding: 5px 0px 10px;
    display: none;
  }
}
.back-link-image {
  @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
    height: 30px;
  }
}
.try-superteam-wrapper__container {
  margin: auto;
  border: 1px solid $theme-border-color;
  max-width: 540px;
  width: 100%;
  border-radius: ($base-border-radius * 2);
  display: flex;
}
.try-superteam-wrapper__container-deatils {
  max-width: 364px;
  width: 100%;
  margin: auto;
  padding: 54px 0;
}
.signup-title {
  @include genearteRemFontSize(22);
  font-weight: $normal-font-weight;
  margin-bottom: 24px;
}
.signup-text {
  // max-width: 330px;
  margin: 16px auto;
  color: $theme-font-heading-secondary-color;
  font-size: 12px;
}
.signup-text span {
  color: $theme-button-primary-color;
}

.mobile-flex-direction {
  @media (max-width: $iphone-devices-max-width+px) {
    flex-direction: column-reverse;
  }
  @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
    flex-direction: column-reverse;
  }
  // @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
  //   flex-direction: column-reverse;
  // }
  // @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
  //   flex-direction: column-reverse;
  // }
}
