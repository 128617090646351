.optout_container {
  padding: 20px 20px 0 20px;
  background-color: #f9f9f9 !important;
  width: 100%;
  // height: calc(100vh - 170px);
  // height: calc(100vh - 120px);
  // max-height: calc(100vh - 120px);
  overflow: auto;
  
  .optout-campaign_container {
    padding: 20px;
    background-color: white;
    border: 1px solid #efefef;
    border-radius: 5px;
    margin-bottom: 20px;
    .keywords_container{
      
    }
  }
 
}
