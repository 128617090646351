@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";

.support-form-wrapper {
  max-width: 364px;
  min-width: 364px;
  width: 100%;
  // @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
  //   max-width: 300px;
  //   min-width: 300px;
  // }
  // @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
  //   min-width: 300px;
  //   max-width: 300px;
  // }
}

.signin-appzo-form {
  margin-top: 24px;
}

.support-form-group {
  margin-bottom: 16px;
  @media (min-width: 320px) and (max-width: $iphone-devices-max-width+px) {
    margin: 16px 0;
  }
  @media (min-width: ($iphone-devices-max-width + 0.02)+px) and (max-width: $mobile-devices-max-width+px) {
    margin: 16px 0;
  }
}

.support-form-group label.support-label {
  color: $theme-primary-color;
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: $normal-font-weight;
}
.support-form-group input.support-form-control {
  line-height: 56px;
  height: 56px;
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius;
  padding: 0 24px;

  &::placeholder {
    color: $theme-secondary-color;
    font-weight: $normal-font-weight;
  }
}
.support-form-group input.support-form-control:focus {
  box-shadow: none;
}
.forgot-password {
  margin: 8px 0 16px;
  color: $theme-button-primary-color;
}

.visbility_icon {
  height: 22px;
  position: absolute;
  right: 10px;
  top: 30%;
  cursor: pointer;
}
.right_visibility {
  height: 24px;
  position: absolute;
  right: 10px;
  top: 30%;
  cursor: pointer;
  color: $theme-button-primary-color;
  font-size: 14px;
}

.login_card {
  // border: 1px solid red;
  // box-shadow: 0px 3px 6px #00000029;
  // width: 35%;
  justify-content: center;
  display: flex;
  // box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;  margin-top: 5%;
  // border-radius: 5px;
  border-radius: 8px;
  border: 1px solid #dadce0;
}

@media (max-width: 567px) {
  .back-arrow-wrapper {
    display: none;
  }
}

.otp-login-page {
  height: calc(100vh - 130px);
  @media screen and (max-width: 567px) {
    height: 100%;
  }

  // @media screen and (min-width: 568px) {
  //   align-items: center;
  // }

  .login_card {
    height: fit-content;
  }
}

.login-mobile-responsive {
  padding-top: 64px;
}

@media (max-width: 767px) {
  .login_card {
    border: none;
  }

  .support-login-wrapper__details {
    margin: 2rem 0 0 0 !important;
  }
}

@media (max-width: 375px) {
  .support-login-wrapper__details {
    margin: 5rem 10px 0 10px !important;
  }

  .support-form-wrapper {
    min-width: fit-content;
    max-width: fit-content;
  }
}
@media (min-width: 768px) {
  .login-mobile-responsive {
    align-items: center;
  }

  .login_card {
    padding: 2rem;
  }
}

@media (max-width: 991px) {
  .height-adjust {
    height: 100svh;
  }
}
@media (min-width: 992px) {
  .height-adjust {
    max-height: calc(100vh - 64px);
    height: 100%;
  }
}
