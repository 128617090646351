@import '@scss/common/_variables.scss';
@import '@scss/common/_mixins.scss';
@import '@scss/common/_extends.scss';

.sub-header-wrapper {
	// padding: 10px 25px;
	height: 60px;
	padding-left: 25px;
	padding-right: 25px;
	box-shadow: 0px 3px 6px #00000029;
	display: flex;
	align-items: center;
	position: sticky;
	top: 64px;
	left: 0;
	right: 0;
	background-color: var(--white-color);
	z-index: 2;
}
// .sub-header-wrapper--dispatch {
// 	padding: 8px 25px;
// }
.sub-header-wrapper__navigation-list-items {
	display: inline-block;
	padding-left: 8px;
	position: relative;
	&:not(:last-child) {
		&::after {
			content: '';
			position: absolute;
			height: 100%;
			width: 1px;
			right: 0;
			top: 0;
			background-color: var(--theme-border-color);
		}
	}
}
.sub-header-wrapper__sub-navigation-title {
	color: var(--theme-grey-color);
	padding: 4px 8px;
	margin: 0 4px;
	@include genearteRemFontSize(16);
	&:hover {
		background-color: #f7fbff;
		color: var(--theme-grey-color);
	}
	&.active {
		color: var(--theme-button-primary-color);
		background-color: #f7fbff;
	}
}
.submenu-name {
	color: var(--theme-font-heading-secondary-color);
	@include genearteRemFontSize(16);
	font-weight: $medium-font-weight;
}
.gap {
	gap: 8px;
}
