@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.settings-vertical-sidebar {
  width: 116px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  overflow-y: auto;
  // padding: 24px;
  align-items: center;
  // border-right: 1px solid #dadce0;
  background-color: #f3f6fc;
  overflow-x: hidden;
  border-right: 1px solid #fff;

  .settings-home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    padding: 16px;
    background: #ebeff3;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    // height: 74px;
  }

  .verticla-tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    padding: 8px;
    // background: #ebeff3;
    font-size: 14px;
    cursor: pointer;
    width: 100%;

    &:hover {
      color: #000;
      .vertical-tab-item-icon {
        background: #ebeff3;
      }
    }

    .vertical-tab-item-icon {
      padding: 4px 16px;
      border-radius: 24px;
      transition: all 0.3s ease-in-out;
    }
  }

  .vertical-active {
    color: #000;

    .vertical-tab-item-icon {
      background: #cbf0f8;

      &:hover {
        background: #cbf0f8;
      }
    }
  }
}

.company-profile-subheader-wrapper,
.settings-header-wrapper {
  padding: 8px 24px;
  // box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  width: 100%;
}

.company-profile-subheader-wrapper {
  height: 56px;
}

.settings-header-wrapper {
  height: 64px;
}

.company-profile-subheader-wrapper_bot {
  padding: 8px 24px;
  box-shadow: 0px 3px 6px #00000029;
  height: 56px;
  display: flex;
  position: sticky;
  background: #fff;
}
.company-profile-subheader-wrapper__submenu-name {
  color: var(--theme-font-heading-secondary-color);
  @include genearteRemFontSize(20);
  font-weight: $medium-font-weight;
}
//company profile form

.company-profile__form-group {
  margin-bottom: 16px;
}
.company-profile__form-title {
  color: var(--theme-font-body-color);
  @include genearteRemFontSize(20);
  font-weight: $medium-font-weight;
  margin-bottom: 24px;
}

label.company-profile__form-label {
  margin-bottom: 8px;
  color: var(--theme-font-terciary-color);
  @include genearteRemFontSize(14);
  font-weight: $medium-font-weight;
}
input.company-profile__form-control.form-control {
  border: 1px solid var(--theme-border-color);
  border-radius: $base-border-radius !important;
  height: 44px;
  line-height: 44px;
  padding: 0 16px;
  &::placeholder {
    @include genearteRemFontSize(16);
    color: var(--theme-border-color);
  }
}
.organisations-wrapper_company {
  // max-width: calc(100% - 257px);
  width: 100%;
  max-height: calc(100vh - 120px);
  height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}

.organisations-wrapper__main-section_create {
  // background: #edf2f7;
  height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  // background-image: url("./../../../Organisations/CreateOrgnisation/org-background.svg");
  // z-index: -9;

  .org-creator-container {
    height: fit-content;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    //   rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }
}

.create-user-form-body__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.create-user-form-body__label {
  font-size: 14px;
}

.create-user-form-body__roles-wrapper {
  margin-top: 8px;
}

.create-user-form-body__roles-wrapper-title {
  margin-bottom: 8px;
}

.create-user-form-body__add-roles-list-cta {
  color: var(--theme-button-primary-color);
  font-weight: $medium-font-weight;
  margin: 12px 0 24px;
  img {
    margin-right: 8px;
  }
}

.create-user-form-body__show-more-roles {
  color: var(--theme-blue-color);
  font-weight: $medium-font-weight;
}
