@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

// .add-more-rolls-wrapper {
//   // box-shadow: 4px 4px 10px #00000029;
//   position: relative;
//   z-index: 2;
// }
// .add-more-rolls-wrapper__header {
//   background: transparent linear-gradient(81deg, #005ba6 0%, #6efaec 100%) 0% 0%
//     no-repeat padding-box;
//   padding: 20px 24px;
// }
// .add-more-rolls-wrapper__header-title {
//   color: var(--white-color);
//   font-weight: $normal-font-weight;
//   @include genearteRemFontSize(20);
// }
// .add-more-rolls-wrapper__sub-title {
//   padding: 16px 24px;
//   color: var(--theme-font-heading-secondary-color);
//   @include genearteRemFontSize(14);
//   box-shadow: 4px 4px 10px #00000029;
//   background-color: var(--white-color);
// }
// .add-more-rolls-wrapper__rolls-list {
//   padding: 16.5px 0;
// }
// .add-more-rolls-wrapper__rolls-wrapper {
//   padding: 0 24px;
// }
// .add-more-rolls-wrapper__rolls-list-item {
//   margin-bottom: 16px;
// }
// .add-more-rolls-wrapper__rolls-list-title {
//   color: var(--theme-font-heading-secondary-color);
//   margin-bottom: 4px;
//   @include genearteRemFontSize(16);
//   font-weight: $normal-font-weight;
// }
// .add-more-rolls-wrapper__rolls-list-text {
//   color: var(--theme-grey-color);
//   @include genearteRemFontSize(14);
//   font-weight: $light-font-weight;
// }
// .add-more-rolls-wrapper__footer {
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   padding: 8px 24px;
//   background-color: #f7fbff;
//   width: 100%;
// }
// .add-more-rolls-wrapper__close-btn {
//   height: 36px;
//   width: 36px;
//   border-radius: 50%;
//   display: flex;
//   background-color: transparent;
//   &:hover {
//     background-color: #248fbd;
//   }
// }
// .add-more-rolls-wrapper__close-icon {
//   margin: auto;
//   height: 22px;
//   width: 22px;
// }

//popup
.tabs-padding {
  padding: 16px 0 0 16px;
}

.edit-fields__wrapper {
  height: 680px;
  padding: 12px 24px;
}

.edit-fileds__form-group {
  margin-bottom: 16px;
}

.edit-fileds__mandatory {
  padding: 12px;
  border: 1px solid $theme-border-color;
  border-radius: $base-border-radius;
  margin-bottom: 16px;
}

.edit-fileds__add {
  color: $theme-button-primary-color;
  @include genearteRemFontSize(14);
  font-weight: $medium-font-weight;
}

.edit-fileds__add-icon {
  height: 24px;
  margin-right: 8px;
  width: 24px;
}
.edit-fields__title {
  margin-bottom: 8px;
  color: #202124;
  @include genearteRemFontSize(16);
  font-weight: $medium-font-weight;
}
//add emp popup
.add-employee-wrapper {
  min-height: 515px;
  height: 515px;
  overflow-y: auto;
  margin-bottom: 20px;
  // max-height: calc(100vh - 130px);
  // height: calc(100vh - 130px);
}
.add-employee-wrapper__search {
  position: relative;
  margin-bottom: 17px;
}
.add-employee-wrapper__input {
  display: block;
  border-width: 0 0 1px 0;
  border-color: $theme-border-color;
  height: 56px;
  line-height: 56px;
  padding: 0 25px 0 50px;
  width: 100%;
  &::placeholder {
    color: #dadce0;
    @include genearteRemFontSize(14);
  }
}
.add-employee-wrapper__search-icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.add-employee-wrapper__footer {
  padding: 8px 24px;
  background-color: #f7fbff;
}

.check-name {
  margin-left: 25px;
  margin-bottom: 16px;
}
.check-name-title {
  font-weight: $medium-font-weight;
  color: #202124;
}
//fields UI

.container {
  display: flex;
  // align-items: stretch;
  // align-items: center;
  height: 100%;
  .column {
    flex: 1;
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
    .column_title {
      font-size: 16px;
      margin-bottom: 20px;
      font-weight: 500;
    }
    .column_container {
      display: flex;
      align-items: center;
      cursor: pointer;
      border: 1px solid #efefef;
      margin-top: 5px;
      padding: 12px;
      border-radius: 5px;
      justify-content: space-between;
      .column_container_title {
        font-size: 14px;
        margin-left: 10px;
      }
      .button {
        border: 1px solid #1d82b7;
        background: transparent;
        padding: 4px;
        font-size: 14px;
        border-radius: 5px;
        color: #1d82b7;
        opacity: 1;
        &:hover {
          opacity: 1;
          border-color: #afd7f8;
        }
      }
    }
  }
  .separator {
    width: 1px;
    background-color: #efefef;
    margin: 3px;
  }
}
//popup
.popup-container {
  // min-height: 30vh;
  width: 65%;
  margin-left: 50px;
}
.popup-submit-container {
  display: flex;
  margin-top: 20px;
  float: right;
  // gap: 16px;
}

.switch-container {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.main_container_fields {
  // max-height: calc(100vh - 124px);
  overflow: hidden;
}

// .main_container_list {
//   max-height: calc(100vh - 170px);
//   overflow: auto;
// }

.custom_field_container{
  width: 100%;
  margin: 0 auto;
  overflow: auto;
  padding-left: 7%;
  padding-right: 7%;
  max-height: calc(100vh - 150px);
}
.main_button_container{
  border: 1px solid #dadce0;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}