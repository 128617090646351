@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.tickets-sub-header-wrapper {
  height: 60px;
  padding-left: 25px;
  padding-right: 25px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  align-items: center;
  position: sticky;
  top: 64px;
  left: 0;
  right: 0;
  background-color: var(--white-color);
  z-index: 2;
  gap: 16px;
}

.ticket-wrapper__main-section_detail {
  background-color: #f7fbff;
  display: flex;
  height: calc(100vh - 64px);
  // height: calc(100vh - 100px);
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}

.pending-wrapper__main-section_after_change {
  display: flex;
  max-height: calc(100vh - 64px);
  // max-height: calc(100vh - 100px);
  overflow-y: auto;
  transition: all 100ms ease-in;
}

.stagelist-swiper {
  .status_breadcrumbs__item,
  .status_breadcrumbs__item_selected {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 24px;
    margin-right: 0 !important;
    position: relative;
    cursor: pointer;
    font-size: 14px;

    &::after {
      box-shadow: 1px -1px 0 1px #fff, 1px -1px 0 1px #fff;
      content: "";
      position: absolute;
      top: 50%;
      right: -7px;
      width: 17px;
      height: 17px;
      z-index: 1;
      border-radius: 0 0 0 25px;
      transform: translateY(-50%) rotate(45deg);
      transition: background 0.3s;
      color: #475867;
    }
  }

  .status_breadcrumbs__item {
    background: #cfd7df;

    &::after {
      background: #cfd7df;
    }

    &:first-child {
      border-top-left-radius: 10px; // Add left border radius for the first item
      border-bottom-left-radius: 10px; // Add left border radius for the first item
    }
  }

  .status_breadcrumbs__item_selected {
    background: #bbdcfe;
    font-weight: 500;

    &::after {
      background: #bbdcfe;
    }

    &:first-child {
      border-top-left-radius: 10px; // Add left border radius for the first selected item
      border-bottom-left-radius: 10px; // Add left border radius for the first selected item
    }
  }
}
