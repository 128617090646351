@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";

.organisations-wrapper__main-section-wrapper {
  // max-width: calc(100% - 257px);
  width: 100%;
  // max-height: calc(100vh - 116px);
  // height: calc(100vh - 116px);
  max-height: calc(100vh - 65px);
  height: calc(100vh - 65px);
  overflow-y: auto;
}

.organisations-wrapper__main-section {
  max-width: 80%;
  margin: 24px auto;
}

.organisations-create {
  // text-align: right;
  margin-bottom: 30px;
  // background-color: #305ae9;
  font-weight: 500;
  // cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.organisations-create_button {
  background-color: #1a73e8;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  height: 40px;
  transition: all 0.3s ease-in-out;
  color: white;
  font-weight: 500;
  cursor: pointer;
  // padding: 10px;
  padding: 0 16px;
  font-size: 14px;

  &:hover {
    background: rgba(var(--button-primary), 0.8);
  }
}
.separator {
  width: 100%;
  height: 1px;
  background-color: #dadce0;
  margin-bottom: 50px;
}
.headertext {
  font-weight: 500;
  font-size: 24px;
}
.desctext {
  font-size: 16px;
  color: rgb(113, 113, 113);
  margin-top: 8px;
  margin-bottom: 20px;
  font-weight: 400;
}

.organisations-box_container {
  border-bottom: 1px solid #dadce0;
  padding: 20px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // cursor: pointer;
}

.organisations-left_container {
  display: flex;
  align-items: flex-start;
  // justify-content: center;
  .organisations-image_container {
    height: 80px;
    width: 200px;
    border: 1px dashed #dadce0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .organisations-left_text_container {
    margin-left: 20px;
    .organisations-title {
      font-size: 20px;
      font-weight: 500;
      color: #343a40;
    }
    .organisations-primary_title {
      font-size: 14px;
      color: #343a40;
      font-weight: 500;
      margin-top: 8px;
    }
    .organisations-secondary_title {
      font-size: 14px;
      color: rgb(151, 151, 151);
      font-weight: 500;
      margin-top: 5px;
    }
  }
}

.organisations-right_container {
  display: flex;
  align-items: center;
  justify-content: end;
  .organisations-right_header_text {
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #dadce0;
    border-radius: 5px;
    padding: 10px 16px;
    color: #343a40;
    margin-right: 30px;
    cursor: pointer;
    transition: all 200ms ease;
    &:hover {
      border: 1px solid #1a73e8;
      background-color: #1a73e8;
      color: white;
    }
  }
  .organisations-right_header_arrow {
    border: 1px solid #dadce0;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;

    img {
      height: 24px;
    }
  }
}

.branding-input {
  border: 1px dashed $theme-border-color;
  width: 260px;
  height: 84px;
  border-radius: $base-border-radius;
  overflow: hidden;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: #fafafa;
    border-color: #1a73e8;
  }
}
.camera-icon {
  margin: auto;
}

@media (max-width: 991px) {
  .organisations-box_container {
    padding: 2rem 0 2rem 0 !important;
  }
  .organisations-left_container {
    flex-direction: column;
    gap: 1rem;

    .organisations-left_text_container {
      margin-left: 0 !important;
    }
  }

  .organisations-right_container {
    margin-top: 1.5rem !important;
    justify-content: center;

    .organisations-right_header_text {
      margin-right: 0 !important;
    }
  }
}

.search-org-input {
  .ant-input-prefix {
    margin-inline-end: 8px !important;
  }
}
