.ant-btn-default {
  border-radius: 20px !important;
  background-color: var(--white-color) !important;
  border: 1px solid var(--theme-border-color) !important;
  font-size: 14px !important;
  white-space: nowrap !important;
  font-family: "Superteams-font", sans-serif !important;
  font-weight: 500;
}
.ant-btn-color-dangerous {
  background-color: red !important;
}
.ant-btn {
  &:disabled {
    opacity: 0.5;
  }
}

.ant-btn-sm {
  height: 24px !important;
  padding: 2px 7px !important;
}

.ant-btn-lg {
  padding: 0.5rem 1.4rem !important;
  //   height: 100% !important;
}

.ant-btn-primary {
  border-radius: 20px !important;
  border: 1px solid var(--theme-border-color) !important;
  font-size: 14px !important;
  white-space: nowrap !important;
  background-color: rgba(var(--button-primary), 1) !important;
  color: var(--white-color) !important;
  font-family: "Superteams-font", sans-serif !important;
}

.ant-btn-dangerous {
  background: #ff4d4f !important;
}

.theme-select-button {
  border-radius: 8px !important;
}

.ant-radio-button-wrapper-checked {
  border: 2px solid;
}

.ant-modal-title {
  font-weight: 500 !important;
  font-size: 17px !important;
}

.users-settings-table {
  .ant-table-body {
    max-height: calc(100vh - 362px) !important;
  }
}

.ant-menu-submenu-selected {
  .ant-menu-submenu-title {
    color: #202124 !important;
    // color: #ff4d4f !important;
    font-weight: 500;

    .ant-menu-item-selected {
    }
  }
}

.ant-menu-submenu-title {
  font-size: 0.9rem !important;
}

.ant-menu-item-selected {
  // background: rgba(var(--primary-color), 0.1) !important;

  .antmenu-sub-selected {
    color: rgba(var(--primary-color), 1) !important;
    font-weight: 500;
  }
}
:where(.css-dev-only-do-not-override-apn68).ant-menu-inline .ant-menu-item {
  line-height: 30px !important;
  height: 30px !important;
}
:where(.css-dev-only-do-not-override-11lehqq).ant-menu-inline .ant-menu-item {
  line-height: 30px !important;
  height: 30px !important;
}

.ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 26px !important;
  height: 26px !important;
  padding-left: 14px !important;
  font-size: 13px !important;
  color: grey;
}

.ant-menu-item {
  margin-block: 0px !important;
  // background: #fff !important;
  // padding-left: 14px !important;
}

.ant-dropdown-menu-submenu-title {
  display: flex;
  gap: 8px;
}

.ant-modal-confirm-title,
.ant-list-item-meta-title {
  font-weight: 500 !important;
}
.ant-drawer-header {
  background: transparent linear-gradient(81deg, #005ba6 0%, #6efaec 100%) 0% 0%
    no-repeat padding-box !important;
  padding: 20px 24px;
}
.ant-drawer-title {
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #fff !important;
}

.ant-drawer-close {
  color: #fff !important;
  font-weight: 600 !important;
}

.ant-select-selector {
  border-radius: 4px !important;
}

.ant-btn-compact-last-item {
  &::before {
    display: none !important;
  }
}

.ant-select-required {
  .ant-select-selector {
    border-left: 2px solid red !important;
  }
}

.delete-trash-icon {
  transition: all 0.3s ease-in-out, color 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: none;
  outline: none;
  border: none;

  &:hover:not(:disabled) {
    .trash-icon {
      color: #ff4d4f !important;
    }
    background: #fff;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      background: none;
    }
  }
}
