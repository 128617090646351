@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.pendingticket-left_container {
  overflow-y: auto;
  min-width: 240px;
  width: 100%;
  max-width: 300px;
  // height: calc(100vh - 64px);

  .ticket-right-side-collapse {
    border-radius: 0 !important;
    border-right: none !important;
    border-top: none !important;
    border-left: 1px solid var(--theme-border-color);

    .ant-collapse-content-box {
      padding: 0 !important;
    }

    .ant-collapse-content,
    .ant-collapse-header,
    .ant-collapse-item {
      border-radius: 0 !important;
    }

    .ant-collapse-header {
      background: #fff !important;
      border: none !important;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
.pendingtask-bottom_container_footer {
  overflow-y: auto;
  height: calc(100vh - 120px);
}
.ticket-wrapper__main-left-section {
  // max-width: 340px;
  width: 100%;
  background-color: var(--white-color);
}
.pending-wrapper__main-section-form {
  // max-width: 340px;
  // width: 100%;
}
.form-group {
  display: block;
  // margin-bottom: 8px;
}
.form-group__label {
  display: block;
  margin: 0px 5px 8px;
  color: var(--theme-font-terciary-color);
  font-weight: $normal-font-weight;
  @include genearteRemFontSize(14);
}
.form-group__input-control {
  width: 100%;
  max-width: 291px;
  min-width: 291px;
  height: 44px;
  line-height: 44px;
  border: 1px solid var(--sky-blue-color);
  padding: 0 16px;
  border-radius: $base-border-radius;
  &::placeholder {
    @include genearteRemFontSize(14);
    color: var(--theme-font-heading-secondary-color);
    font-weight: $normal-font-weight;
  }
  &:disabled {
    background-color: white;
  }
}

.form-group__input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  width: 18px;
}

.task-list-wrapper {
  max-width: 55px;
  width: 100%;
  padding: 12px 0px 0px;
  cursor: pointer;
}

.task-list-container {
  height: 36px;
  width: 36px;
  // background-color: var(--theme-button-primary-color);
  background-color: #eaf0f6;
  border: 1px solid #cbd6e2;
  border-radius: 50%;
  margin: 0 auto;
}

.task-list {
  height: 18px;
  width: 18px;
  margin: auto;
}

.task-list-title {
  font-weight: $medium-font-weight;
  @include genearteRemFontSize(12);
  margin-top: 5px;
}

.pending-wrapper__main-left-section-details {
  padding-left: 24px;
}
.pending-wrapper__main-section-btn {
  border: 1px solid var(--sky-blue-color);
  color: var(--theme-button-primary-color);
  height: 40px;
  line-height: 40px;
  margin-top: 24px;
  width: 100%;
  border-radius: $base-border-radius;
  // max-width: 291px;
  width: 100%;
  background-color: #f7fbff;
}
.pending-wrapper__main-section-header {
  &:hover {
    text-decoration: underline;
    color: $theme-main-text-color;
  }
}
.pending-wrapper__main-left-section_header-text {
  color: $theme-main-text-color;
  font-weight: 500;
  font-size: 15px;
  font-family: $font-family;
}
.pending-wrapper__main-left-section_header-image-back {
  height: 14px;
  width: 14px;
}
.pending-wrapper__main-left-section_header-image {
  height: 22px;
  width: 22px;
}

.form-container {
  .form-container_name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    margin-bottom: 10px;
  }
  .form-container_desc {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .form-container_desc-text {
      font-weight: 400;
      font-size: 14px;
      color: #33475b;
      font-family: "Lexend Deca", Helvetica, Arial, sans-serif;
      // line-height: 24px;
    }
  }
}

.form-container_name-text {
  font-size: 20px;
  font-weight: 600;
  color: #33475b;
}

.form-container:hover .pending-wrapper-hover_edit_section {
  opacity: 1;
  transition: all 1s ease-in;
}

//tag styles
// rgba(var(--primary-color), 1)

.hexagon {
  min-width: 60px;
  background-color: transparent;
  border-radius: 16px;
  border: 1px solid #efefef;
  margin-bottom: 5px;
  padding: 5px;
  padding-left: 10px;
  margin-right: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  .close_icon {
    right: 3px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    // top: -1px;
    transition: all 500ms ease-out;
    &:hover {
      background-color: white;
      border-radius: 50%;
    }
  }
}

.color_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
.tag-popup-container {
  // width: 85%;
  // margin: 0 auto;
  width: auto;
}
.tag-popup-button-container {
  display: flex;
  justify-content: flex-end;
}

.view_more_container {
  border-top: 1px solid var(--theme-border-color) !important;
}
