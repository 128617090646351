@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.email_container {
  overflow-y: auto;
  max-height: calc(100vh - 120px) !important;
  overflow-x: hidden;
  display: flex;
}
.email_body_container {
    padding: 16px 0 0 16px;
  // margin: 20px;
}

.main_container {
  width: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
}
.container {
  width: 70% !important;
  margin: 0 auto;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.email_body {
  width: 250px;
  text-align: center;
  padding: 15px;
}

.email_button_container {
  border: none;
  outline: none;
  margin: 0 auto;
  // width: 70%;
  display: flex;
  // justify-content: center;
  margin-top: 2%;
  cursor: pointer;
  p {
    border: 1px solid #1a73e8;
    padding: 10px;
    border-radius: 5px;
    color: white;
    background-color: #1a73e8;
    &:hover {
      transition: all 500ms ease;
      background-color: #0566e4;
    }
  }
}

//tab2

.email_wrap-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2%;
}
.email_wrap-image_container {
  border: 1px solid #efefef;
  // padding: 20px;
  border-radius: 5px;
  margin-right: 20px;
  height: 140px;
  width: 140px;
  .email_wrap-image_container_image {
    display: flex;
    justify-content: center;
    align-items: center;

  }
  cursor: pointer;

  &:hover {
    transition: border 1s ease;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}

//tab-3
.list_container {
  width: 90%;
  margin: 0 auto;
  margin-top: 3%;

  li {
    font-size: 16px;
    line-height: 2rem;
    color: #616e88;
  }
}
.button_left_container {
  border: none;
  outline: none;
  width: 90%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 2%;
  cursor: pointer;
  p {
    border: 1px solid #1a73e8;
    padding: 10px;
    border-radius: 5px;
    color: white;
    background-color: #1a73e8;
    &:hover {
      transition: all 500ms ease;
      background-color: #0566e4;
    }
  }
}

//------------ Email Templates -----------//

.activebar__main-body-list {
  padding-bottom: 16px;
  cursor: pointer;
  position: relative;
  &:not(:last-child) {
    margin-right: 24px;
  }
  &.active {
    .activebar__main-body-link {
      color: rgba(var(--primary-color),1);
    }
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: rgba(var(--primary-color),1);
      bottom: 0;
      left: 0;
    }
  }
  &:hover {
    .activebar__main-body-link {
      color: rgba(var(--primary-color),1);
    }
    &::after {
      content: "";
      position: absolute;
      height: 3px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      width: 100%;
      background-color: rgba(var(--primary-color),1);
      bottom: 0;
      left: 0;
    }
  }
}
.activebar__main-body-link {
  color: var(--theme-font-terciary-color);
  font-weight: $medium-font-weight;
  @include genearteRemFontSize(15);
  transition: 0.3s all ease-in-out;
}
.activebar__main-body-list_active {
  padding-bottom: 16px;
  cursor: pointer;
  position: relative;
  &:not(:last-child) {
    margin-right: 24px;
  }
  .activebar__main-body-link {
    color: rgba(var(--primary-color),1) !important;
  }
  &::after {
    content: "";
    position: absolute;
    height: 3px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
    background-color:rgba(var(--primary-color),1);
    bottom: 0;
    left: 0;
  }
}

.sender_button{
  border: none;
  background-color: #000;
  color: white;
  padding: 7px;
  border-radius: 14px;
  font-size: 14px;
}