@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.task-assigned {
  border: 1px solid #e8f0fe;
  padding: 15px 16px;
  background-color: $white-color;
  // margin-bottom: 16px;
}
.pending-wrapper__main-body-title {
  color: #5f6368;
  @include genearteRemFontSize(14);
  font-weight: $normal-font-weight;
  padding-right: 10px;
  span.pending-wrapper__main-body-title-bold {
    font-weight: $medium-font-weight;
  }
}
.pending-wrapper__main-body-status {
  color: #5f6368;
  @include genearteRemFontSize(14);
  font-weight: $normal-font-weight;
  &--overdue {
    color: #fc541f;
  }
}
.pending-wrapper__main-body-description {
  color: #202124;
  @include genearteRemFontSize(14);
  font-weight: $medium-font-weight;
  margin-left: 10px;
}
.showdiv {
  opacity: 1;
  visibility: visible;
  max-height: 100vh;
  padding-top: 10px;
}
.hidediv {
  opacity: 0;
  visibility: hidden;
  max-height: 0;
}
.task-assigned__icon-image {
  transition: all 100ms ease-in-out;
  margin-right: 5px;
}
.task-assigned__icon-image-active {
  transform: rotate(90deg);
}

.pending-wrapper__main-body-description-section {
  transition: all 100ms ease-in-out;
}

//task UI section

.task_main-container {
  padding: 10px 18px;
  // border: 1px solid #efefef;
  background-color: white;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 90%;
  margin: 0 auto;
  border-radius: 5px;
}
.dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: rgb(207, 204, 204);
}

.task_main-container {
  cursor: pointer;
  transition: 0.4s all ease-in-out;
  &:hover {
    opacity: 1;
  }
  .notes_action_container {
    opacity: 0;
    transition: opacity 0.4s ease-in-out; /* Optional transition for smoother effect */
  }
  &:hover .notes_action_container {
    opacity: 1;
  }
}
