@import "@scss/common/_variables.scss";
@import "@scss/common/_mixins.scss";
@import "@scss/common/_extends.scss";

.header-wrapper {
  background-color: var(--theme-font-heading-secondary-color);
  // padding: 0px 25px;
  height: 64px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar_items__wrapper {
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  height: 64px;

  .navbar_item {
    width: 100%;
    position: relative;
    height: -webkit-fill-available;
    display: flex;
    align-items: center;

    .navbar_dropdown_container {
      display: flex;
      flex-direction: column;
      position: relative;
      transition: all 0.3 ease-in;
    }

    .navbar_nav__link {
      text-decoration: none;
      text-wrap: nowrap;
      width: fit-content;
      gap: 3px;
      height: 64px;
      padding: 0 1.4rem;
      border-bottom: 3px solid inherit;
      cursor: pointer;
      color: var(--theme-border-color);
      font-weight: $light-font-weight;
      @include genearteRemFontSize(14);
      transition: 3ms all ease-in-out;
      display: flex;
      align-items: center;
      position: relative;
      border-bottom: 3px solid var(--theme-font-heading-secondary-color);

      .msg-header-notification {
        position: absolute !important;
        top: 16px;
        right: 10px;
        background: #25d366;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        animation: blink 2s infinite;
      }

      &:hover {
        color: var(--theme-border-color);
        background-color: var(--black-color);
      }

      .chevron-icon {
        transition: transform 0.3s ease;
      }

      .rotate {
        transform: rotate(180deg);
      }

      .new-feature-tag {
        position: absolute;
        top: 6px;
        right: 4px;
        background: #55a84d;
        border-radius: 20px;
        padding: 1px 8px;
        font-size: 0.7rem;
      }
      .whatsapp-msg-notification {
        position: absolute;
        top: 6px;
        right: 4px;
      }
      &.active {
        background: var(--black-color);
        border-bottom: 3px solid rgba(var(--primary-color), 1);
        margin-bottom: 2px;
      }
    }

    .navlink_active {
      background: var(--black-color);
      border-bottom: 3px solid rgba(var(--primary-color), 1);
      margin-bottom: 2px;
    }

    .navbar_dropdown_menu {
      position: absolute;
      top: 100%;
      margin-top: 3px;
      left: 0;
      z-index: 9999;
      background: var(--theme-font-heading-secondary-color);
      transition: all 0.3s ease-in-out;
      display: none;
      min-width: 220px;
      background: #000;
      padding: 1rem 0;
      border-radius: 10px;

      .subnav_dropdown_item {
        white-space: nowrap;
        padding: 1rem 0.8rem;
        color: var(--theme-border-color);
        font-weight: $light-font-weight;
        transition: all 0.2s ease-in;
        margin: 0 1rem;
        border-radius: 6px;

        &:hover {
          background-color: var(--theme-font-heading-secondary-color);
        }

        // &:nth-child(3) {
        //   border-bottom: 1px solid rgba(var(--primary-color), 1);
        // }

        &.active {
          color: rgba(var(--primary-color), 1) !important;
          font-weight: bold !important;
          position: relative;
          display: flex;
          align-items: center;
          border-bottom: 1px solid rgba(var(--primary-color), 1);
          background-color: var(--theme-font-heading-secondary-color);
        }
      }

      .subnav_dropdown_item_active {
        color: rgba(var(--primary-color), 1) !important;
        font-weight: bold !important;
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(var(--primary-color), 1);
        background-color: var(--theme-font-heading-secondary-color);

        // &::before {
        //   content: "";
        //   width: 4px;
        //   height: 4px;
        //   background: #fff;
        //   position: absolute;
        //   border-radius: 50%;
        //   background: rgba(var(--primary-color), 1);
        //   // top: 0;
        //   left: 0;
        // }
      }

      .navbar-seperator {
        width: 80%;
        border: 1px solid rgba(var(--primary-color), 1);
        padding: 0 0.8rem;
      }
    }

    &:hover {
      .navbar_dropdown_menu {
        display: grid;
      }
    }
  }
}

.navbar_nav__link > .subnavlink_active {
  border-bottom: 3px solid rgba(var(--primary-color), 1);
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.custom-dropdown-menu {
  // background-color: #000 !important;
  color: #000 !important;
  min-width: 200px; // Set the desired width
}
.subnav_dropdown_item {
  font-size: 15px !important;
  font-weight: 500 !important;
  white-space: nowrap;
  padding: 0.3rem 0 !important;
  color: #f0f0f0 !important;
  font-weight: $light-font-weight;
  transition: all 0.2s ease-in;
  margin: 0 1rem;

  &.active {
    color: rgba(var(--primary-color), 1) !important;
  }
}

.navbar-dropdown-container {
  background: var(--black-color) !important;
  min-width: 220px;

  .navbar-dropdown-menu {
    background: var(--black-color);
    color: #ffffff;
    border-radius: 6px;
    padding-bottom: 8px;

    .ant-dropdown-menu-title-content {
      padding: 4px 0 !important;
    }
  }

  .ant-dropdown-menu-item-group-title {
    color: #858585 !important;
  }

  .ant-dropdown-menu-item-group-title {
    padding: 5px 22px !important;
  }

  // .ant-dropdown-menu-item-only-child {
  // padding: 0.6rem 0 !important;
  // }

  .ant-dropdown-menu-item-active {
    background: var(--theme-font-heading-secondary-color) !important;
  }

  .navbar-dropwdown-divider {
    height: 1px;
    margin: 4px 20px !important;
    background-color: rgb(143 143 143 / 45%) !important;
  }
}
