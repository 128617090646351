.otp-group {
    display: flex;
    width: 100%;
    max-width: 340px;
    column-gap: 8px;
  }
  
  .otp-input {
    width: 100%;
    height: 50px;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    max-width: 58px;
  }
  .first-input {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none;
    &:focus{
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  
  .last-input {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
    &:focus{
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }