.main-container {
  background-color: #f6f6f6;
  overflow: auto;
  height: calc(100vh - 116px);
  padding: 16px;

  .box-container {
    display: flex;
    width: 100%;
    // padding: 16px; /* Optional padding for spacing */
    flex-wrap: wrap;
    .box {
      // min-width: 300px;
      width: 24%;
      min-height: 170px;
      padding: 20px 25px 25px 25px;
      border-radius: 5px;
      background-color: white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      margin-right: 1%;
      margin-bottom: 15px;
    }
  }

  .box-graph-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .box-graph {
      min-width: 100%;
      max-width: 100%;
      height: 310px;
      padding: 20px 25px 25px 25px;
      border-radius: 5px;
      background-color: white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      margin-right: 1%;
      margin-bottom: 15px;
    }
  }

  .box-list-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .box-list {
      min-width: 49%;
      max-width: 49%;
      min-height: 310px;
      padding: 20px 25px 25px 25px;
      border-radius: 5px;
      background-color: white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      margin-right: 1%;
      margin-bottom: 15px;
    }

    .box-graph {
      min-width: 930px;
      max-width: 930px;
      height: 310px;
      padding: 20px 25px 25px 25px;
      border-radius: 5px;
      background-color: white;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }

  .graph-cards-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    gap: 1rem;
    margin-top: 0.5rem;

    .graph-cards-wrapper {
      padding: 1.5rem;
      border: 2px solid var(--theme-border-color);
      border-radius: 10px;
      min-width: 34rem;

      .bar-graph-container {
        width: 100%;
        height: 6rem;
      }

      .graph-card-sub-text {
        font-weight: 600;
        font-size: 14px;
        color: var(--theme-grey-color);
      }
    }
  }

  .graph-card-border-bottom {
    border-bottom: 2px solid var(--theme-border-color);
    margin: 3px 0;
  }

  .graph-summary {
    padding: 0 1rem;
    margin-bottom: 1rem;
    border-right: 2px solid var(--theme-border-color);
  }

  .dark-text-color {
    color: var(--theme-font-heading-secondary-color);
  }

  .font-weight-500 {
    font-weight: 500;
  }

  .graph-card-top-text {
    font-weight: 500;
    font-size: 14px;
    color: var(--theme-grey-color);
  }

  .count-summary {
    font-size: 1.6rem;
    margin: 6px 0;
    font-weight: 500;
  }

  .graph-color-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .overview-container {
    display: flex;
    width: 100%;
    min-width: 100%;
    // justify-content: center;
    padding: 2rem 6rem;
    margin-top: 1rem;

    .overview-wrapper {
      width: 100%;
      padding: 1.5rem;
      border: 2px solid var(--theme-border-color);
      border-radius: 10px;
      overflow-x: hidden;

      .add-overflow {
        overflow-x: auto;
      }
    }
  }
}

.graph-list-container {
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  cursor: pointer;
  transition: all 500ms ease;
  &:hover {
    background-color: rgba(var(--primary-color), 0.06);
  }
}

.add-nowrap {
  white-space: nowrap !important;
}

.main_overview_container {
  width: 75%;
  margin: auto;
}

.today_volume_container {
  width: 50%;
  .main {
    border: 1px solid #dadce0;
    border-radius: 5px;
    min-height: 30vh;
    .overview_table {
      th {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        padding-top: 20px;
        h6 {
          font-size: 14px;
        }
      }

      td {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
        p {
          font-size: 14px;
        }
      }
    }
  }
}
.volume_overview_container {
  width: 50%;
  .main {
    border: 1px solid #dadce0;
    border-radius: 5px;
    min-height: 30vh;
    .overview_table {
      th {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        padding-top: 20px;

        h6 {
          font-size: 14px;
        }
      }

      td {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
        p {
          font-size: 14px;
        }
      }
    }
  }
}
.overview_container {
  .main {
    border: 1px solid #dadce0;
    border-radius: 5px;
    min-height: 10vh;
  }
}
.performence_overview_container {
  .main {
    border: 1px solid #dadce0;
    border-radius: 5px;
    min-height: 10vh;
  }
}

.with-vertical-line {
  border-right: 1px solid #dadce0; /* Change the color and width as needed */
  height: 70px;
  margin: auto; /* Add this line to center the element horizontally */
}

.dashboard-demo-info {
  background: #000;
  position: absolute;
  bottom: 30px;
  left: 50%;
  padding: 10px 30px;
  transform: translateX(-50%);
  border-radius: 20px;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  z-index: 2;

  .dashboard-dismiss-option {
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3 ease-in-out;

    &:hover {
      opacity: 0.9;
    }
  }
}

.dashboard-demo-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.377) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 200px;
  z-index: 1;
  width: 100%;
}

.dashboard-image-wrapper {
  height: calc(100vh - 112px);
  overflow-y: auto;

  .dashboard-demo-image {
    position: inherit;
  }
}

.dashboard-slider-container,
.dashboard-slider-container-footer {
  .ant-drawer-body {
    background: #f6f5fa;
    // background: #f6f6f6;
  }
}
.dashboard-slider-container-footer {
  .ant-drawer-body {
    margin-bottom: 70px;
  }
}
.dashboard_charttype_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.dashboard_charttype {
  // border: 1px solid #bdc8d3;
  padding: 10px;
  width: 100%;
  // text-align: center;
  cursor: pointer;
  box-shadow: 0 0 0 1px transparent;
  // height: 84%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  // &:hover {
  //   border: 1px solid #1a73e8;
  // }

  .gindex-highlight {
    font-size: 32px;
    color: #da4f4f;
  }

  .gindex-growth-percentage {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 20px;
  }
}
.standard-highlight {
  font-size: 32px;
}
.standard-highlight-percentage {
  font-size: 32px;
  font-weight: 500;
}
.db_color_success {
  color: #05a357 !important;
}
.db_color_negative {
  color: #fc541f !important;
}
.standard-growth-percentage {
  color: #05a357;
  display: flex;
  align-items: center;
  gap: 2px;
}
.height-100 {
  height: 100%;
}
.dashboard_border {
  // border: 1px solid #dadce0;
  padding: 24px;
  max-height: 340px;
  height: 100%;
  max-width: 100%;
  border-radius: 8px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: all 0.3s ease-in-out;
  position: relative;
  // grid-column: span 3;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .dashboard-more-option {
      display: flex;
    }

    .dashboard-card-heading-eye {
      display: block;
    }
  }

  .dashboard-card-heading-eye {
    display: none;
  }

  .dashboard-more-option {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    display: none;
    align-items: center;
    gap: 8px;
  }
}

.ant-slider-custom-footer {
  background: #fff;
  position: absolute;
  width: 100%;
  width: 100%;
  height: 70px;
  bottom: 0;
  right: 0;
  background-color: rgb(245, 248, 250) !important;
  border-top: 1px solid rgb(203, 214, 226);
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 30px;
}
.dashboard__container {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
  height: calc(100vh - 116px);
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
  // background: #f6f6f6;
  background: #f6f5fa;
  padding: 2rem;
  gap: 1rem;

  @media only screen and (max-width: 1520px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.db_maximized_view {
  height: calc(100vh - 116px);
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
  padding: 2rem;
  position: relative;
  background: #fff;

  .maximized-chart-container {
    max-height: calc(100vh - 216px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .maximized-dashboard-more-option {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.db-charttype-creator {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.chart-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.pie-chart-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  // max-width: 800px;
  width: 100%;
  margin: 0 auto;
  max-height: calc(100vh - 216px);
}
.custom-submenu-dropdown-container {
  // width: 100%;
  .custom-submenu-dropdown {
    line-height: 1.5;
    // font-size: 1rem;
    width: 100%;
    background-color: transparent;
    border: 1px solid #dadce0;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 0 10px;
    transition: all 200ms ease;
    height: 44px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-dropdown-open {
    border: 1px solid #408dfb;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  }
}

.dashboard-style-selector {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  width: 100%;

  .dashboard_charttype-container {
    width: 100%;
  }
}

.chart-details-table-drawer {
  .ant-drawer-body {
    padding: 0 !important;
  }
}

.chart-details-table {
  max-height: calc(100vh - 156px);
  overflow-y: auto;
}

.dashboard-card-heading {
  font-size: 16px;
  font-weight: 500 !important;
  color: #202124;
  cursor: pointer;
  padding-bottom: 1rem;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    text-decoration: underline;
  }
}
