.chatbox-wrapper {
  // height: calc(100vh - 64px);
  // overflow: hidden;

  button {
    outline: none;
    border: none;
    // background: none;
  }

  .arrow-down {
    width: 1rem;
  }

  .header-image {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }

  .image-rounded {
    border-radius: 50%;
    width: 1rem;
  }

  .image-width {
    width: 1rem;
  }

  .flag-icon {
    width: 1.2rem;
  }

  .chatbox-heading {
    font-size: 1.5rem;
    padding: 1rem 1.5rem;
    border-bottom: 2px solid #ebebeb;
  }

  .id-icon {
    width: 1.2rem;
  }

  .message-blue-text {
    color: #215fff;
  }

  // .dashboard-button {
  //   font-weight: 300;
  //   background-color: rgba(var(--button-primary), 1);
  //   color: var(--white-color);
  //   border-radius: 20px;
  //   padding: 10px;
  //   border: 1px solid var(--theme-border-color);
  //   font-size: 14px;
  // }

  .chatbox-container {
    display: flex;
    font-family: "SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui,
      BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
      "Kohinoor Devanagari", sans-serif;
    height: calc(100vh - 135px);

    .chatbox-left-container {
      width: 35rem;
      height: calc(100vh - 64px);
      overflow-y: hidden;
      border-right: 1px solid #dadce0;
      background: #fff;

      .filter-section-wrapper {
        position: sticky;
        top: 0;
        .filter-section {
          background-color: #f0f2f5;
          padding: 1rem 1.5rem;
          height: 62px;
          border-bottom: 1px solid #efefef;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .all-message-count {
            font-size: 0.9rem;
            font-weight: 500;
          }

          .filter-cotnainer {
            display: flex;
            gap: 0.5rem;
          }
        }
      }

      .message-count-section {
        padding: 0 1.5rem;
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;

        button {
          font-size: 0.9rem;
          font-weight: 500;
          color: #215fff;
        }
      }

      .bottom-border {
        border-bottom: 2px solid #ebebeb;
      }

      .active-chat {
        background: #ebf4f8;
      }

      .chat-lits-wrapper {
        height: calc(100vh - 194px);
        overflow-y: auto;
        padding-bottom: 40px;
      }

      .chat-list {
        border-bottom: 1px solid #ebebeb;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        padding: 0.8rem 1.5rem;
        background-color: #fff;
        transition: all 0.2s ease-in;

        &.active {
          background-color: #f0f2f5;
        }

        &:hover {
          background-color: #f5f6f6;
        }
        .time-noti {
          font-size: 0.7rem;
          color: #898989;

          .attach-icon {
            width: 1rem;
          }
        }

        .typing-message {
          font-size: 0.9rem;
          color: #00b200;
          font-weight: 500;
        }

        .recieved-message {
          font-size: 0.9rem;
          font-weight: 500;
          color: #898989;
        }

        .selected-profile-image {
          position: relative;
          border: 3px solid #00b100;
          border-radius: 50%;
          padding: 2px;

          .chat-message-icon {
            width: 1.5rem;
            height: 1.5rem;
            position: absolute;
            bottom: -6px;
            right: -9px;
            background: #fff;
            padding: 4px;
            border-radius: 50%;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          }
        }

        .nonselected-profile-image {
          // border: 3px solid #ffffff;
        }
      }
    }

    .chatbox-center-container {
      width: 100%;
      // border-left: 2px solid #ebebeb;
      .no-conversation {
        background: #fff !important;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .whatsapp_no {
        height: 250px;
        width: 450px;
      }
      .connect-account {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // background: #ebebeb;
        width: 100%;

        .integration-heading {
          font-size: 1.5rem;
          padding: 3rem 0 1rem 0;
        }

        .icons-wrapper {
          padding: 1rem 0;
          display: flex;
          gap: 1rem;

          .appzo-logo {
            width: 10rem;
          }
        }

        .integration-subheading {
          font-size: 1.25rem;
        }

        .integration-description {
          font-size: 1rem;
          text-align: center;
          padding: 0.5rem 0;
        }

        button {
          padding: 0.6rem 0.8rem;
          margin: 1rem;
        }
      }

      .conversation-wrapper {
        display: flex;

        .header-name {
          display: flex;
          gap: 0.3rem;
          align-items: center;
          font-size: 0.9rem;
          color: #000;
          background: none;
        }

        .conversation-section {
          width: 100%;
          // height: calc(100vh - 135px);
          position: relative;
          background: #fcecd68f;

          .conversation-header {
            display: flex;
            justify-content: space-between;
            padding: 0.6rem 1rem;
            border-bottom: 1px solid #ebebeb;
            height: 62px;
            background-color: #f0f2f5;

            .header-left-section {
              display: flex;
              gap: 0.8rem;

              input {
                font-size: 0.8rem;
                border: none;
                border-bottom: 1px solid #a3a3a3;
              }

              .message-icon {
                width: 1.2rem;
                height: 1.2rem;
              }
            }

            .header-right-section {
              display: flex;
              gap: 0.5rem;

              .icon-size {
                width: 1.2rem;
              }
            }
          }

          .message-section {
            // position: relative;
            // height: calc(100vh - 192px);
            background-image: url(./whatsapp-background.png);
            background-repeat: round;
            height: calc(100vh - 128px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-y: hidden;
            background-repeat: no-repeat;
            background-size: cover;

            .new-contact-preview {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              background: #fff;
              border-radius: 8px;
              width: 40%;
              padding: 2rem 0;
              margin: 1rem 0;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
                rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
            }

            .message-heading {
              display: flex;
              justify-content: center;
              font-size: 0.8rem;
              color: #767676;
              margin: 10px 0;
            }

            .chat-section {
              width: 100%;
              // display: flex;
              flex-direction: column;
              justify-content: space-between;
              overflow-y: hidden;
              overflow-x: hidden;
              // height: calc(100vh - 26rem);
              height: calc(100vh - 175px);

              .sender-details-wrapper {
                padding: 0.5rem 1rem;
                display: flex;
                flex-direction: column;
                align-items: end;

                .sender-details {
                  display: flex;
                  justify-content: flex-end;
                  text-align: end;
                  gap: 0.6rem;
                  width: 100%;

                  .sender-user-name {
                    font-size: 14px;
                    padding-right: 40px;
                    margin-bottom: 4px;
                    color: var(--theme-font-terciary-color);
                    font-weight: 500;
                  }

                  .sender-left-section {
                    display: flex;
                    // flex-direction: column;
                    justify-content: end;
                    width: 100%;
                    gap: 6px;

                    .sender-private-message {
                      background: #ebf8ff;
                      padding-top: 0.6rem;
                      padding-left: 0.6rem;
                      padding-right: 0.6rem;
                      border-radius: 10px 0 10px 10px;
                      font-size: 0.9rem;
                      max-width: 80%;
                      // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
                      //   rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
                      box-shadow: 0 1px 0.5px #0b141a17;
                      position: relative;

                      &::before {
                        width: 0;
                        height: 0;
                        border-left: 18px solid #ebf8ff;
                        border-right: 5px solid transparent;
                        border-bottom: 22px solid transparent;
                        position: absolute;
                        top: 0;
                        right: -17px;
                        content: "";
                      }
                    }

                    .sender-message {
                      background: var(--status-color, #d9fdd4);
                      padding-top: 0.6rem;
                      padding-left: 0.6rem;
                      padding-right: 0.6rem;
                      border-radius: 10px 0 10px 10px;
                      font-size: 0.9rem;
                      max-width: 80%;
                      // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
                      //   rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
                      box-shadow: 0 1px 0.5px #0b141a17;
                      position: relative;

                      &::before {
                        width: 0;
                        height: 0;
                        border-left: 18px solid var(--status-color, #d9fdd4);
                        border-right: 5px solid transparent;
                        border-bottom: 22px solid transparent;
                        position: absolute;
                        top: 0;
                        right: -17px;
                        content: "";
                      }
                    }

                    .more-options-wrapper {
                      max-width: 80%;

                      .more-options-button {
                        visibility: hidden;
                        transition: all 0.2s ease-in;
                      }

                      &:hover .more-options-button {
                        visibility: visible;
                      }
                    }

                    // .more-options-button:hover + .hover-options {
                    //   display: block;
                    // }

                    .show-options {
                      display: block !important;
                    }

                    .hover-options {
                      background: #fff;
                      // border: 1px solid #a3a3a3;
                      position: absolute;
                      top: -5rem;
                      left: 1rem;
                      display: none;
                      // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                      // box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
                      border-radius: 6px;
                      box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
                        rgba(0, 0, 0, 0.05) 0px 5px 10px;

                      .hover-content {
                        display: flex;
                        gap: 3px;
                        padding: 0.5rem;

                        .hover-content-options {
                          white-space: nowrap;
                          font-size: 0.8rem;
                        }
                      }
                    }

                    .notes-wrapper {
                      position: relative;
                      cursor: pointer;
                      transition: all 0.2s ease-in;

                      .notes-section {
                        position: absolute;
                        right: -10rem;
                        top: 0;
                        border-radius: 6px;
                        box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
                          rgba(0, 0, 0, 0.05) 0px 5px 10px;
                        background: #fff;
                        padding: 0.5rem;
                        min-width: 3rem;
                        width: 100%;
                        font-size: 0.8rem;
                        text-align: start;
                        display: none;

                        .notes-sub-heading {
                          font-weight: 500;
                          margin-bottom: 1rem;
                        }
                      }
                    }

                    .notes-wrapper:hover .notes-section {
                      display: block;
                    }
                  }
                  .sender-right-section {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .user-image {
                      width: 2rem;
                      border-radius: 50%;
                    }

                    .double-blue-tick {
                      width: 1.4rem;
                    }
                  }
                }

                .send-time {
                  // margin-right: 2.6rem;
                  font-size: 0.7rem;
                  margin-top: 3px;
                  color: #667781;
                  white-space: nowrap;
                }
              }

              .reciever-details-wrapper {
                padding: 0.2rem 2rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .reciever-details {
                  display: flex;
                  justify-content: flex-end;
                  text-align: start;
                  gap: 0.6rem;
                  width: 100%;

                  .reciever-right-section {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    width: 100%;
                    position: relative;

                    .reciever-message {
                      background: #fff;
                      // padding: 1rem;
                      position: relative;
                      padding-top: 0.6rem;
                      padding-left: 0.6rem;
                      padding-right: 0.6rem;
                      border-radius: 0px 10px 10px 10px;
                      font-size: 0.9rem;
                      max-width: 80%;
                      box-shadow: 0 1px 0.5px #0b141a17;

                      &::before {
                        width: 0;
                        height: 0;
                        border-right: 18px solid #ffffff;
                        border-left: 5px solid transparent;
                        border-bottom: 22px solid transparent;
                        position: absolute;
                        top: 0;
                        left: -17px;
                        content: "";
                      }
                    }
                  }

                  .reciever-left-section {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .user-image {
                      width: 2rem;
                      border-radius: 50%;
                    }

                    .double-blue-tick {
                      width: 1.4rem;
                    }
                  }
                }

                .send-time {
                  // margin-left: 2.6rem;
                  font-size: 0.7rem;
                  margin: 5px 0;
                  color: #667781;
                  // position: absolute;
                  float: right;
                  white-space: nowrap;
                }
              }
            }
          }

          .section-open {
            position: absolute;
            top: 4rem;
            right: -1.2rem;
            outline: none;
            border: none;
            background: none;
            background: #fff;
            border-radius: 50%;
            width: 2.2rem;
            height: 2.2rem;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            z-index: 1;

            .section-open-icon {
              width: 0.8rem;
              transition: all 0.1s ease-in;
            }
          }
        }

        .profile-section {
          // background: aliceblue;
          // width: 1.2rem;
          position: relative;
          transition: all 0.3s ease-in-out;
          border-left: 1px solid #ebebeb;
          // padding: 1rem;
          height: calc(100vh - 65px);
          overflow-y: auto;

          .hidden {
            display: none;
          }

          .show {
            display: block;
            transition: all 0.3 ease-in-out;
          }

          .related-content-images {
            display: flex;
            gap: 1rem;
            align-items: center;
            margin: 0.5rem;
            margin-bottom: 1.8rem;

            .active {
              -webkit-box-shadow: 0px 1px 5px 1px rgba(240, 22, 240, 0.56);
              -moz-box-shadow: 0px 1px 5px 1px rgba(240, 22, 240, 0.56);
              box-shadow: 0px 1px 5px 1px rgba(240, 22, 240, 0.56);
            }

            .related-image {
              width: 2rem;
              border-radius: 50%;
            }
          }

          .conversation-wrapper {
            border: 1px solid #ebebeb;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            padding: 0.6rem;
            margin: 6px 0;

            .text-grayed {
              font-size: 0.8rem;
              color: #898989;
            }

            .tags-container {
              display: flex;
              align-items: center;
              gap: 0.3rem;

              .tag-icon {
                width: 1rem;
              }

              .tags-heading {
                font-size: 0.9rem;
                color: #898989;
              }

              .tag1 {
                color: #215fff;
                background: #cce8ff;
                padding: 0.1rem 0.3rem;
                border-radius: 5px;
                font-size: 0.9rem;
              }
              .tag2 {
                color: #d43f3f;
                background: #ffeeee;
                padding: 0.1rem 0.3rem;
                border-radius: 5px;
                font-size: 0.9rem;
              }
              .close-icon {
                width: 1rem;
              }

              .text-normal {
                font-weight: 400;
                font-size: 0.9rem;
              }
            }

            .id-container {
              display: flex;
              align-items: center;
              gap: 0.3rem;
              font-size: 0.9rem;

              .id-heading {
                color: #898989;
              }
            }
          }

          .vendor-wrapper {
            border: 1px solid #ebebeb;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            padding: 0.6rem;
            margin: 1rem 0;
            font-size: 0.9rem;

            .vender-header {
              display: flex;
              justify-content: space-between;

              .vendor-heading {
                color: #008f00;
                background: #e7ffe7;
                padding: 2px 6px;
                border-radius: 6px;
              }

              .actions-heading {
                font-weight: 500;
              }
            }

            .vendor-profile-wrapper {
              display: flex;
              gap: 0.5rem;
              align-items: center;
              margin: 0.8rem 0;
              position: relative;

              &::after {
                content: "22";
                position: absolute;
                bottom: -3px;
                left: -5px;
                background: #000;
                border-radius: 50%;
                color: #fff;
                padding: 2px;
                width: 20px;
                height: 20px;
                font-size: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .vendor-image {
                border: 3px solid #00d400;
                border-radius: 50%;
                padding: 2px;
              }

              .vendor-profile-details {
                .user-designation {
                  font-size: 0.8rem;

                  .user-company {
                    color: #215fff;
                  }
                }
              }
            }

            .profile-social-section {
              display: flex;
              align-items: center;
              gap: 0.5rem;

              .bold-profile-text {
                font-weight: 500;
                font-size: 0.8rem;
              }
            }

            .add-to-section {
              border-top: 1px solid #ebebeb;
              padding: 1rem 0 0.5rem 0;
              display: flex;
              gap: 1rem;
              justify-content: center;

              .icon-background {
                background: var(--theme-deep-yellow-color);
                border-radius: 50%;
                width: 2.4rem;
                height: 2.4rem;
                display: flex;
                justify-content: center;
                align-items: center;

                .add-to-icons {
                  width: 1rem;
                  fill: #008f00;
                }
              }
            }
          }
        }

        .profile-section.expanded {
          width: 36rem;
        }

        .rotated {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.note-section {
  background: #fff3df;
  padding: 2rem 3rem;
  margin: 1.5rem;
  border-radius: 6px;

  .note-heading {
    font-size: 1rem;
  }

  .note-points-wrapper {
    margin-top: 0.3rem;
    .note-points {
      list-style: disc;
      font-size: 0.9rem;
    }
  }
}

.meet-criteria-content {
  max-width: 800px;
  width: 100%;
  padding: 2rem;
  border-radius: 0.5rem;
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.new-contact-content {
  // width: 100%;
  padding: 1rem;
  border-radius: 6px;
  min-width: 30rem;
  width: fit-content;

  .new-contact {
    display: grid;
    row-gap: 1rem;
  }
}

// .dashboard-button {
//   font-weight: 300;
//   background-color: rgba(var(--button-primary), 1);
//   color: var(--white-color);
//   border-radius: 20px;
//   padding: 10px;
//   border: 1px solid var(--theme-border-color);
//   font-size: 14px;
// }

// .dashboard-button--sceondary {
//   border-radius: 20px;
//   padding: 10px;
//   background-color: var(--white-color);
//   border: 1px solid var(--theme-border-color);
//   font-size: 14px;
// }

.header_container {
  img {
    height: 40px;
    width: 40px;
  }
}
.account_details_container {
  border: 1px solid #efefef;
  min-height: 5vh;
  padding: 10px;
  width: 500px;
  border-radius: 5px;
}
.whatsapp_file {
  // min-height: 200px;
  width: 300px;
  object-fit: contain;
  max-height: 500px;
  // height: 100%;
}

.whatsapp_file-replay {
  width: 50px;
  height: 50px;
  border-radius: 0 10px 10px 0;
}

.epr-category-nav.epr_q53mwh.epr_-lzq3oa.epr_rco1ad.epr_-8ygbw8.epr_1q9rpn {
  display: none !important;
}

.contact-search-wrapper {
  position: sticky;
  top: 0;
  z-index: 100;

  .message_search {
    background-color: white;
    padding: 2%;
    position: relative;
    // border-bottom: 1px solid #efefef;
    display: flex;
    align-items: center;
    gap: 10px;

    .contact-search {
      height: 38px;
      width: 100%;
      background-color: #f0f2f5;
      border: none;
      border-radius: 8px;
      padding-left: 40px;
      font-size: 14px;
      &:focus {
        box-shadow: none;
      }
    }
    .search-icon {
      position: absolute;
      top: 16px;
      left: 21px;
    }
  }

  .wa-filter-button {
    background: #f0f2f5;
    padding: 6px 16px;
    border-radius: 24px;
    font-size: 14px;
    color: #54656f;
    transition: all 0.3s ease-in-out;
  }

  .wa-filter-selected {
    background: #e7fce3;
    color: #008069;
  }

  .chat-summary-filter {
    height: 42px;
    .chat-filter {
      transition: all 0.3s ease-in-out;

      &:hover {
        background: #f7fcff;
      }
    }

    .chat-filter-active {
      border-bottom: 2px solid #3182ce;
      background: #ebf8ff;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: #bee3f8;
      }
    }
  }
}

.whatsapp_document {
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #efefef;
    transition: all ease 500ms;
  }
}

.document-review-section {
  background: #f0f2f5;
  // width: 1.2rem;
  position: absolute;
  transition: all 0.3s ease-in-out;
  border-left: 1px solid #ebebeb;
  // padding: 1rem;
  height: calc(100vh - 54px - 66px);
  overflow-y: auto;
  .hidden {
    display: none;
  }
  margin-top: 66px;
  .show {
    display: block;
    transition: all 0.3 ease-in-out;
  }
}

.document-review-section.expanded {
  width: 72%;
  z-index: 10;
}

.chat-doc-footer {
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: 80px;
}
.chat-doc-header {
  width: 100%;
  background-color: #e9edef;
  height: 62px;
}

.message-bottom-section {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  width: 100%;
  background: #fff;
  // background: #f0f2f5;
  // height: 12rem;

  .message-editor-top-section {
    display: flex;
    justify-content: space-between;
    // padding: 0.5rem 0;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding: 0 1rem;

    .editor-tab {
      display: flex;
      gap: 1rem;

      button {
        padding: 0.6rem 0;
      }

      .active {
        border-bottom: 3px solid #000;
      }
      .non-active {
        color: #898989;
      }
    }
  }

  .message-editor {
    width: 90%;
    height: 2.5rem;
    border: none;
    overflow: auto;
    background-color: white;
    border-radius: 10px;
    resize: none;
    padding-left: 45px;
    font-size: 15px;
    font-weight: 300;
    transition: all 0.2s ease-in-out;
    // margin-top: 10px;

    &::placeholder {
      font-size: 14px;
    }
  }
  .emoji-editor {
    position: absolute;
    left: 75px;
    cursor: pointer;
  }
  .bottom-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;

    .bottom-left-section {
      display: flex;
      gap: 0.6rem;

      .happy-face {
        width: 1.6rem;
      }

      .link-icon {
        width: 1.1rem;
      }

      .picture-icon {
        width: 1.1rem;
      }

      .link-icon {
        width: 1.1rem;
      }
    }

    .bottom-right-section {
      display: flex;
      gap: 1rem;

      .send-and-close {
        border: 1px solid #d1d1d1;
        padding: 0.3rem 1rem;
        border-radius: 5px;
        font-size: 0.9rem;
      }

      .send {
        padding: 0.3rem 1rem;
        border-radius: 5px;
        font-size: 0.9rem;
        color: #fff;
        background-color: #215fff;
      }
    }
  }
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.doc_thumnail {
  position: absolute;
  bottom: 0;
  height: 80px;
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
  border-top: 1px solid #ebebeb;
  z-index: 999;
}

.doc_preview_image {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 4px;
  &:hover {
    opacity: 0.8;
  }
  &.active {
    border: 2px solid #408dfb;
    border-radius: 4px;
  }
}
.doc_preview_main_image {
  height: 300px;
  width: 300px;
  border: 1px solid #dadce0;
  padding: 5px;
  overflow: hidden;
  border-radius: 5px;
  object-fit: contain;
  // background-color: white;
}
.chat_cross {
  position: absolute;
  top: 3px;
  right: 371px;
  // background-color: white;
  cursor: pointer;
}
.replay-message-wrapper {
  background: #fff;
  transition-delay: all 0.6s ease-in-out;
}

.reply_message_container {
  display: flex;
  min-height: 50px;
  border: 1px solid #efefef;
  border-left: 5px solid #06cf9c;
  // border-top-left-radius: 10px;
  // border-bottom-left-radius: 10px;
  border-radius: 10px;
  // margin-bottom: 8px;
  background-color: #d1f4cc;
  // background-color: #f8f6f6;

  .replayed-background {
    background: #d1f4cc;
  }
}

.reciever-replay {
  background: #f4f5f5 !important;
}

.sender-left-section {
  position: relative;
  .arrow-container {
    position: relative;
    display: none;
    position: absolute;
    top: 50%;
    left: -30px;
    cursor: pointer;
    transform: translate(-50%, -50%);
    z-index: 9999 !important;

    .whatsapp-more-options {
      display: none !important;
      position: absolute;
      top: 0;
      right: -4px;
      background: #fff;
      padding: 8px;
      display: flex;
      gap: 8px;
      flex-direction: column;
      align-items: baseline;
      border-radius: 8px;
      // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      width: 164px;

      // &:hover {
      //   display: flex;
      // }

      .w-more-options {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        transition: all 0.2s ease-in;
        padding: 8px 0;
        z-index: 999 !important;
        width: 100%;
        border-radius: 4px;
        background: none;

        &:hover {
          color: #000;
          background: #fdfeff;
        }
      }
      .download-icon {
        width: 14px;
      }
    }
  }

  // .arrow {
  //   visibility: hidden;
  // }

  // .whatsapp_more {
  //   display: none;
  //   cursor: pointer;
  //   position: absolute;
  //   top: -14px;
  //   right: -10px;
  //   // background: rgb(255, 255, 255);
  //   // background: linear-gradient(
  //   //   90deg,
  //   //   rgba(255, 255, 255, 0) 0%,
  //   //   rgba(255, 255, 255, 1) 100%,
  //   //   rgba(255, 255, 255, 0) 100%
  //   // );
  //   border-radius: 50%;
  //   background-image: radial-gradient(white, #fff0);
  // }
}

.add-border {
  border-bottom: 1px solid #e9eaed;
  width: 100%;
}

.message-line-height {
  line-height: 1.3;
  text-align: start;
  word-wrap: break-word;
  word-break: break-word;
}

.reciever-right-section {
  position: relative;
  .arrow-container {
    position: relative;
    display: none;
    position: absolute;
    top: 50%;
    right: -52px;
    cursor: pointer;
    transform: translate(-50%, -50%);
    z-index: 9999 !important;

    .whatsapp-more-options {
      display: none !important;
      position: absolute;
      top: 0;
      left: -4px;
      background: #fff;
      padding: 8px;
      display: flex;
      gap: 8px;
      flex-direction: column;
      align-items: baseline;
      border-radius: 8px;
      // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      width: 164px;

      // &:hover {
      //   display: flex;
      // }

      .w-more-options {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        transition: all 0.2s ease-in;
        padding: 8px 0;
        z-index: 999 !important;
        width: 100%;
        border-radius: 4px;
        background: none;

        &:hover {
          color: #000;
          background: #fdfeff;
        }
      }
      .download-icon {
        width: 14px;
      }
    }
  }
}

.sender-left-section,
.reciever-right-section {
  &:hover {
    .arrow-container {
      display: block;

      &:hover {
        .whatsapp-more-options {
          display: block !important;
        }
      }
    }
    // .whatsapp-more-options {
    //   display: block;
    // }
    .whatsapp_more {
      display: block;
    }
  }
}

.whatsappcount {
  background: #25d366;
  color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 12px;
}

.accordion-arrow {
  transition: transform 0.3s;
}

.accordion-rotate {
  transform: rotate(180deg);
}

.profile-details {
  height: 62px;
  position: sticky;
  background: #fff;
  width: 100%;
  top: 0;
  z-index: 9;
}

.accordion-content {
  border-left: 4px solid #06cf9c;
  border-radius: 6px;
  // background: rgba(var(--primary-color), 0.08);
  background: #f0f2f5;
}

.accordion-header {
  color: rgba(var(--theme-font-terciary-color)) !important;
}

.inbox_button {
  padding: 10px;
  height: 43px;
  border: 1px solid #dadce0 !important;
  border-radius: 5px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  font-size: 14px;
  color: #3182ce;
  width: fit-content;
}

.chat-date-container {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 5px;
  z-index: 9;
  margin-bottom: 5px;

  .chat-date-header {
    box-sizing: border-box;
    display: inline-block;
    flex: none;
    max-width: 100% !important;
    padding: 5px 12px 6px 12px;
    font-size: 12.5px !important;
    line-height: 21px;
    color: #54656f;
    text-align: center;
    background-color: #fffffff2;
    border-radius: 7.5px !important;
  }
}

.filter_count__container {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #06cf9c;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  top: -10px;
  left: -11px;
  font-size: 11px;
  cursor: pointer;
}

.ant-inbox-tab {
  background: #fff;

  .ant-tabs-nav-wrap {
    padding: 0 20px;
  }

  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }

  .ant-tabs-tab {
    padding: 12px 0 6px !important;
  }
}

.inbox-canned-response-container {
  height: 300px;
  overflow-y: auto;
  display: grid;
  width: 100%;
  gap: 4px;
  min-width: 400px;

  .inbox-canned-response-card {
    display: flex;
    gap: 16px;
    min-width: 400px;
    cursor: pointer;
    background: #f5f7f9;
    padding: 4px 8px;
  }
}
