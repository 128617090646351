.home-container-wrapper {
  height: calc(100vh - 64px);
  overflow-y: auto;

  .home-banner-card {
    background: linear-gradient(91.58deg, #0083ff 0.04%, #004f99 100%);
    width: 100%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    margin: 1.5rem 0;
    border-radius: 8px;
    padding: 14px 30px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    border: 1px solid #fff;

    .light-grayed-text {
      color: #f8f8f8;
    }

    .dashed-underline {
      text-decoration: underline dashed;
      color: #f8f8f8;
      margin-left: 3px;
      text-underline-offset: 5px;
      text-decoration-color: #90cdf4;
    }
  }

  .three-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 100%;

    .home-grid-card {
      display: flex;
      flex-direction: column;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
      border-radius: 8px;
      justify-content: space-between;
      margin-bottom: 1.5rem;

      .home-grid-header {
        padding: 20px;
      }

      .home-grid-footer {
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
        border-radius: 0 0 8px 8px;
        color: #0083ff;
        padding: 14px 0;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }

  .three-grid-container .home-grid-card:nth-child(1) {
    background: #def9c4;
  }

  .three-grid-container .home-grid-card:nth-child(2) {
    background: #e5d9f2;
  }

  .three-grid-container .home-grid-card:nth-child(3) {
    background: #d1e9f6;
  }

  .home-accordion {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    border: 1px solid #eee !important;
    border-radius: 8px !important;

    .ant-collapse-content-active {
      border-bottom: 1px solid #eee !important;
    }

    .ant-collapse-header {
      padding: 20px 20px;
    }
    .ant-collapse-header-text {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .video-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .redirect-link {
    font-size: 15px;
    font-weight: 400;
    color: #3182ce;
    transition: all 0.3 ease-in-out;

    &:hover {
      color: #0083ff;
    }
  }
}

.intro-wrapper {
  // background-image: url("./components/intro-background.webp");
  // background-repeat: no-repeat;
  // background-size: inherit;
  // background-position: 50%;
  // height: calc(100vh - 64px);
  // background-size: 10rem;
  background: linear-gradient(
    180deg,
    #fefaf3,
    hsla(36, 71%, 99%, 0) 50%,
    hsla(0, 0%, 100%, 0)
  );
  border-radius: 8px;
  padding: 20px 30px;
}

.intro-skip {
  background: transparent;
  outline: none;
  border: none;
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #69b1ff;
    transform: translateX(6px);
  }
}

.home-intro-modal {
  .ant-modal-content {
    padding: 0 !important;
  }
}
