.tickets-card-middle-wrapper,
.tickets-table-middle-wrapper {
  padding: 10px 0px 10px 0px;
  width: 100%;

  .ticket-card-wrapper {
    height: calc(100vh - 246px);
    overflow-y: auto;
    padding: 4px 20px;
    align-content: baseline;
  }
}

.tickets-card-middle-wrapper {
  background: #f7fbff;
}

.tickets-table-middle-wrapper {
  height: calc(100vh - 124px);
  overflow: hidden;
}
