@import '@scss/common/_variables.scss';
@import '@scss/common/_mixins.scss';

.try-superteam-wrapper__details-list {
  border-bottom: 1px solid $theme-border-color;
}
.try-superteam-wrapper__list-item {
  display: flex;
  align-items: flex-start;
  color: $theme-font-heading-primary-color;
  font-weight: $light-font-weight;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 24px;
  }
}
.try-superteam-wrapper__list-check {
  margin-right: 8px;
}
.try-superteam-wrapper__company-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 25px;
}