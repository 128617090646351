.image-container {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #e8f0fe;
  justify-content: center;
  display: flex;
  align-items: center;
  .header-text {
    font-size: 14px;
    font-weight: 500;
  }
}

.ticket-card-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  height: fit-content;
}
